.dis-original-input {
    display: none;
}

.order__dis__option label .icon {
    font-size: 150%;
    color: @brand-primary;
    vertical-align: middle;
    padding-right: 5px;
}

.order__dis__option label button .icon {
    padding-right: 0;
}

.dis-new-input {
    margin-right: 5px;
    
    .icon {
        position: relative;
        top: 2px;
        
        &.icon-plus {
            line-height: 100%;
            font-weight: normal;
            top: 0;
        }
    }
}

.rtl .dis-new-input {
    margin-left: 5px;
    margin-right: 0;
}

.order__dis__option label {
    margin-bottom: 0;
}

.order__listing__item__name__dis .products-content__info .icon {
    display: none;
}