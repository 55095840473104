.auto-renewal-display {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    padding-bottom: 8px;

    .ar-status {
        text-transform: uppercase;
    }

    .ar-edit {
        color: @brand-primary;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: @link-hover-color;
        }
    }
}

.renewal-modal {
    .dialog-styles();

    .renewal-actions {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        line-height: 19px;

        .renewal-yes-no {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        [for="auto-renewal"],
        [for="auto-renewal-on"],
        [for="auto-renewal-off"] {
            font-weight: 600;
        }
    }

    .btn-success {
        .main-cta();
        width: auto;
        padding: @ar-buttons-padding;
    }

    .btn-secondary {
        .secondary-cta();
        width: auto;
        padding: @ar-buttons-padding;
    }

    .dialog-actions {
        padding: 20px 0 0;
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        border-top: 1px solid @legend-border-color;
    }
}