/* ==========================================================================
   Layout Example: https://secure.avangate.com/order/checkout.php?PRODS=4638151&QTY=1&CART=1&CARD=2&ORDERSTYLE=nLWo45apqro=
   ========================================================================== */

/* ==========================================================================
   LAYOUT 1
   ========================================================================== */
#order__cart__contents.layout-1 {
    .order__box {
        background: @products-box-content-bg;
    }
    
    .order__box .order__box__title {
        background: transparent;
        border-bottom: 0px solid transparent;
        
        .order__box__aux2 {
            border-bottom: 1px solid @main-border-color;
            padding-left: 0;
            margin-left: @padding-small-horizontal;
            padding-right: 0;
            margin-right: @padding-small-horizontal;
        }
    }
    
    .order__box .order__box__content {
        background: transparent;
    }
    
    #order__totals {
        border-top: 1px dashed @main-border-color;
        padding-top: @padding-large-vertical;
    }
    
    .order__listing__item__name {
        font-weight: bold;
        .order__product__options {
            font-weight: normal;
            padding-top: 5px;
        }
    }
    
    .products-total-right {
        width: 100% !important;
        padding-top: 5px;
        padding-bottom: 15px;
    }
    
    #order__sub__total__row td {
        display: block;
    }
    
    .products-total-row .prod-total-text {
        float: left;
    }
    
    .currency .select-box {
        width: 100% !important;
    }
    
    // Hide stuff
    .products-content__title,
    
    .order__listing__item__delivery,
//    .order__listing__item__qty,
    .order__listing__item__unit__price,
    .order__listing__item__total__price,
//    .order__listing__item__remove,
    
//    .order__checkout__summary tr,
    
    #order__sub__total__row td.products-total-left,
    
    div.products-total-row.products-subtotal.order__sub__total,
    
    .currency__label,
    
    .order__product__discount .order__listing__item__qty,
    .order__product__discount .order__listing__item__delivery,
    .order__product__discount .order__listing__item__unit__price,
    .order__product__discount .order__listing__item__remove {
        display: none;
    }
    
    // Show stuff
    .order__checkout__summary tr#order__sub__total__row,
    .order__checkout__summary tr#order__sub__total__row tr {
        display: table-row;
    }
    
    .order__product__discount .order__listing__item__total__price {
        display: table-cell;
        text-align: right;
    }

    @media screen and (min-width: 550px) and (max-width: 991px) {
        .order__listing__item__unit__price {
            display: table-cell;
        }
    }
}

.products-content__info {
    color: @product-content-color;
}

.rtl #order__cart__contents.layout-1 {
    .products-total-row .prod-total-text {
        float: right;
    }
    
    .order__product__discount .order__listing__item__total__price {
        text-align: left;
    }
}