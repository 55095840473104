/* ==========================================================================
   HOTLINE
   ========================================================================== */
#header .hotline {
    margin-top: @hotline-margin-top;
    
    & > .icon {
        font-size: 120%;
        position: relative;
        top: 3px;
    }
    
    .hotline-label {
        font-weight: bold;
    }
    
    .hotline-language-separator {
        padding: 0 5px;
    }
    
    .hotline-language,
    .hotline-language-content {
        display: inline-block;
    }
    
    .hotline-language .select-box {
        border: 0px solid transparent;
        box-shadow: 0px 0px 0px 0px transparent;
        -webkit-box-shadow: 0px 0px 0px 0px transparent;
        height: 28px;
        padding: 0;
        width: 100px !important;
        background: transparent;
    }
    
    .hotline-language .select {
        height: 28px;
    }
    
    .hotline-language .select-box span.selected-option {
        height: 28px !important;
        line-height: 28px !important;
        color: @header-color;
    }
    
    .hotline-language .select-box-arrow-wrapper {
        height: 28px;
    }
    
    .hotline-language .select-box {
        .select-box-arrow-wrapper .select-box-arrow {
            border-top-color: @header-color;
        }
        &.order__select__focus .select-box-arrow-wrapper .select-box-arrow {
            border-bottom-color: @header-color !important;
        }
    }
    
    .language__label {
        display: none;
    }
    
    .hotline-support {
        font-size: 95%;
        padding-top: 3px;
    }
}