/* ==========================================================================
   LAYOUT 4
   ========================================================================== */
#order__cart__contents.layout-4 {
    .cross__sell__products__listing {
        background: @gray-lighter;
        margin-left: -@padding-small-horizontal;
        margin-right: -@padding-small-horizontal;
        margin-bottom: @table-cell-padding;
    }
    
    @media screen and (max-width: 599px) {
        .order__listing__header__image,
        .order__listing__item__image {
            display: none;
        }
    }
}