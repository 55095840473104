//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn,
a.btn {
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: @btn-font-weight;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    .box-shadow(@btn-shadow);
    .button-size(@padding-base-vertical;
        @padding-base-horizontal;
        @font-size-base;
        @line-height-base;
        @border-radius-base);
    .user-select(none);
    &, &:active, &.active {
        &:focus, &.focus {
            .tab-focus();
        }
    }
    &:hover, &:focus, &.focus {
        color: @btn-default-color;
        text-decoration: none;
        .box-shadow(@btn-shadow-hover);
    }
    &:active, &.active {
        outline: 0;
        background-image: none;
        .box-shadow(@btn-shadow-active);
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: @cursor-disabled;
        pointer-events: none; // Future-proof disabling of clicks
        .opacity(.65);
        .box-shadow(none);
    }
}
// Alternate buttons
// --------------------------------------------------
.btn-default,
a.btn-default {
    .button-variant(@btn-default-color;
    @btn-default-bg;
    @btn-default-border);
}
.btn-primary,
a.btn-primary {
    .button-variant(@btn-primary-color;
    @btn-primary-bg;
    @btn-primary-border);
    font-weight: bold;
    &:hover {
        .button-variant(@btn-primary-bg;
        @btn-primary-color;
        @btn-primary-border);
    }
}
.btn-submit {
    margin: 0 auto;
}
// Success appears as green
.btn-success,
a.btn-success {
    .button-variant(@btn-success-color;
    @btn-success-bg;
    @btn-success-border);
    .box-shadow(@btn-success-shadow);
    &:hover, &:focus {
        .box-shadow(@btn-success-shadow-hover);
    }
    &:active {
        .box-shadow(@btn-success-shadow-active);
    }
}
// Info appears as blue-green
.btn-info,
a.btn-info {
    .button-variant(@btn-info-color;
    @btn-info-bg;
    @btn-info-border);
}
// Warning appears as orange
.btn-warning,
a.btn-warning {
    .button-variant(@btn-warning-color;
    @btn-warning-bg;
    @btn-warning-border);
}
// Danger and error appear as red
.btn-danger,
a.btn-danger {
    .button-variant(@btn-danger-color;
    @btn-danger-bg;
    @btn-danger-border);
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
    color: @link-color;
    font-weight: normal;
    border-radius: 0;
    &, &:active, &.active, &[disabled], fieldset[disabled] & {
        background-color: transparent;
        .box-shadow(none);
    }
    &,
    &:hover, &:focus, &:active {
        border-color: transparent;
    }
    &:hover, &:focus {
        color: @link-hover-color;
        text-decoration: @link-hover-decoration;
        background-color: transparent;
    }
    &[disabled],
    fieldset[disabled] & {
        &:hover, &:focus {
            color: @btn-link-disabled-color;
            text-decoration: none;
        }
    }
}
// Button Sizes
// --------------------------------------------------
.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    .button-size(@btn-padding-large-vertical;
    @btn-padding-large-horizontal;
    @font-size-large;
    @line-height-large;
    @border-radius-small);
}
.btn-sm {
    // line-height: ensure proper height of button next to small input
    .button-size(@btn-padding-small-vertical;
    @btn-padding-small-horizontal;
    @font-size-small;
    @line-height-small;
    @border-radius-small);
}
.btn-xs {
    .button-size(@btn-padding-xs-vertical;
    @btn-padding-xs-horizontal;
    @font-size-small;
    @line-height-small;
    @border-radius-small);
}
// Block button
// --------------------------------------------------
.btn-block {
    display: block;
    width: 100%;
}
// Vertically space out multiple block buttons
.btn-block + .btn-block {
    margin-top: 5px;
}
// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
}
}
