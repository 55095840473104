.field-wrapper {
    position: relative;
    
    .tooltip {
        position: absolute;
    }
    
    .field-helper {
        position: absolute;
        bottom: auto;
        
        .field-helper-popup {
            width: 220px;
        }
    }
}