/* HEADER
   -------------------------------- */
#header {
    width: @header-width;
    background: @header-bg;
    color: @header-color;
    padding: @header-padding;
    border: @header-border;
    border-width: @header-border-width;
    margin: @header-margin;
    
    .select-box.no-box-select,
    .no-box-select .select-box {
        .selected-option {
            color: @header-color;
        }
        .select-box-arrow-wrapper .select-box-arrow {
            border-top-color: @header-color;
            
            &.icon {
                color: @header-color;
            }
        }
        &.order__select__focus .select-box-arrow-wrapper .select-box-arrow {
            border-bottom-color: @header-color !important;
            
            &.icon {
                color: @header-color;
            }
        }
    }
}

#subheader {
    width: @subheader-width;
    background: @subheader-bg;
    padding: @subheader-padding;
    border-bottom: @subheader-border;
    margin: @subheader-margin;
}
body.product #subheader { display: none; }