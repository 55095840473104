/* ==========================================================================
   PRESET 4
   ========================================================================== */

#steps-wrap.preset-4 {
    #steps {
        .step-nr-wrapper {
            vertical-align: middle;
        }
        .step-nr {
            border: 0px solid transparent;
            background: @cart-steps-inactive-color;
            line-height: 22px;
            width: 22px;
        }
        .step-nr-text {
            font-weight: normal;
        }
        .step.checked .step-icon {
            display: inline-block;
            line-height: 22px;
            width: 22px;
            height: 22px;
            position: relative;
            top: 4px;
        }
        .step.checked .step-nr-text {
            display: none;
        }
    }
}

.rtl #steps-wrap.preset-4 #steps .step-name {
    padding-right: 0;
    padding-left: 10px;
}
