/* ==========================================================================
   LAYOUT 3
   ========================================================================== */
#order__cart__contents.layout-3 {
    .order__listing__header__delivery,
    .order__listing__item__delivery {
        display: none;
    }
    
    .order__box .order__box__content .order__box__aux2 {
        padding: 0;
    }
    
    .order__cart__totals__wrapper,
    .order__cart__vat__wrapper {
        width: 30%;
    }
    
    .order__cart__updates__wrapper {
        border-top: 1px solid @main-border-color;
        vertical-align: top;
        height: 122px;
    }
    
    .order__cart__updates__wrapper td:empty {
        display: none;
    }
    
    .order__cart__totals__wrapper,
    .order__cart__vat__wrapper {
        border-left: 1px solid @main-border-color;
    }
    
    .order__cart__products__wrapper,
    .order__cart__totals__wrapper,
    .order__cart__updates__wrapper,
    .order__cart__vat__wrapper {
        padding: @box-content-padding;
    }
    
    .order__cart__totals__table,
    .order__vat__note {
        float: right;
        text-align: right;
    }
    
    .order__cart__totals__wrapper {
        vertical-align: top;
    }
    
    .order__cart__vat__wrapper {
        vertical-align: bottom;
        
        .order__vat__note {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
    .products-billing-txt-wrapper {
        padding-bottom: @box-margin-bottom;
    }
    
    .order__total {
        padding-top: @padding-base-vertical;
    }
}

// Right to left text
body.rtl #order__cart__contents.layout-3 {
    .order__cart__totals__wrapper,
    .order__cart__vat__wrapper {
        border-left: 0px solid transparent;
        border-right: 1px solid @main-border-color;
    }
    
    div.products-billing-txt {
        float: left;
    }
    
    .order__cart__totals__table,
    .order__vat__note {
        float: left;
        text-align: left;
    }
}

// Media queries
@media screen and (max-width: 844px) {
    #order__cart__contents.layout-3 {
        .order__cart__products__wrapper,
        .order__cart__totals__wrapper,
        .order__cart__updates__wrapper,
        .order__cart__vat__wrapper {
            display: table;
            width: 100%;
            border: 0px solid transparent;
        }
        
        #order__products {
            padding: @box-content-padding;
        }
    }
}

@media screen and (max-width: 675px) {
    #order__cart__contents.layout-3 {
        .order__listing__header__remove {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    #order__cart__contents.layout-3 {
        .order__listing__header__delivery,
        .order__listing__item__delivery {
            display: none;
        }
    }
}

@media screen and (max-width: 550px) {
    #order__cart__contents.layout-3 {
        .order__listing__header__total__price,
        .order__listing__item__total__price {
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    #order__cart__contents.layout-3 {
        .order__listing__header__unit__price,
        .order__listing__item__unit__price {
            display: none;
        }
    }
}