
/*popup*/

.pricing-options-dialog{
  box-shadow: @popup-box-shadow !important;
  .ui-dialog-titlebar{
    background: @gray-lighter !important;
    .ui-dialog-title {
      padding-top: 7px;
      color: @product-name-color;
    }
    .ui-dialog-titlebar-close {
      background: none !important;
      &:focus,
      &:active,
      &:hover {
        background: none !important;
        padding: 10px;
      }
    }
  }
  .pricing-options-popup{
    border: solid @table-border-color;
    border-width: 0 1px 1px;
    .pricing-options-group{
      vertical-align: top;
      font-size: @font-size-base;
      .pricing-option {
        select,
        input[type="text"],
        input[type="number"] {
          width: 100%;
          display: block;
        }
      }
      label {
        margin-left: 5px;
        white-space: normal;
      }
      .pricing-options-group-title{
        margin-bottom: 5px;
        color: @text-color;
      }
    }
  }
  .pricing-options-footer{
    .pricing-options-price-cta{
      div{
        display: inline-block;
        &.pricing-options-price{
          float: left;
          font-weight: bold;
          vertical-align: middle;
          font-size: @font-size-large;
          margin-top: 5px;
          padding-right: 10px;
        }
      }
    }
  }
}

.pricing-options-popup-modal {
  tr > td.pricing-options-group:not(:last-child) {
    border-right: 1px solid @table-border-color;
  }
}
@media screen and (max-width: 959px) {
  .pricing-options-popup-modal {
    tr > td.pricing-options-group {
      border-right: 0 !important;
    }
  }
}

/*inline*/

.order__listing__item__name{
  .pricing-options-inline{
    .pricing-options-group{
      border-width: 0;
      padding: 5px 5px 5px 12px;
    }
  }
}