#wia-popup {
    display: none;
    
    .avangate-logo-wrapper {
        text-align: left;
    }
    .vendor-logo-wrapper {
        text-align: right;
    }
    .icons {
        padding-top: 20px;
    }
}