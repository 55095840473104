.order__testmode {
    display: none !important;
}

.custom-test-mode {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 35px;
    background-color: #E5EEFF;
    border-radius: @border-radius-base;
    font-weight: 600;
    color: @text-color;

    @media screen and (max-width: @screen-sm-max) {
        top: 75px;
        z-index: 5;
    }

    .close-test-mode {
        color: @text-color;
        top: 0;
        right: 0;
        position: absolute;
        cursor: pointer;

        &:hover {
            color: rgba(255, 0, 0, 0.3);
        }

        &:after {
            content: "\00d7";
            font-size: 25px;
            padding: 5px;
        }
    }
}