@media screen and (max-width: @dialog-full-width-breakpoint) {
    .ui-dialog {
       margin: 0 auto !important;
       position: fixed !important;
       top: 0 !important;
       bottom: auto !important;
       left: auto !important;
       right: auto !important;
       width: 100% !important;
       height: 100% !important;
    }
    .ui-dialog .ui-dialog-content {
        height: 100% !important;
        padding-top: 60px;
        position: relative;
        top: -60px;
        padding-bottom: 60px;
        margin-bottom: -120px;
    }
    .ui-dialog-titlebar.ui-widget-header,
    .ui-dialog .ui-dialog-buttonpane {
        position: relative;
        z-index: 999;
    }
}

.ui-dialog {
    .ui-dialog-titlebar.ui-widget-header {
        border: 0px solid transparent;
        background: transparent;
        padding: .3em .3em .5em 1em;
    }
    
    border: 0px solid transparent;
}

.ui-dialog-titlebar{
  z-index: 1;
  padding: 0.5em 1em 0.3em 1em;
}
