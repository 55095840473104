/* ==========================================================================
   Layout Example: https://secure.avangate.com/order/checkout.php?ORDERSTYLE=nLW8m5a5nH4=&PRODS=4573076,4573088&QTY=457&CURRENCY=USD&DCURRENCY=USD&CART=1&CARD=2CLEAN_CART=all
   ========================================================================== */

/* ==========================================================================
   LAYOUT 2
   ========================================================================== */
#order__cart__contents.layout-2 {
    .order__box {
        background: @products-box-content-bg;
    }
    
    .order__box .order__box__title {
        display: none;
    }
    
    .order__box .order__box__content {
        background: transparent;
    }
    
    #order__totals .products-total-right {
        padding-top: @padding-large-vertical;
        padding-bottom: @padding-large-vertical;
        padding-left: @padding-small-horizontal;
        padding-right: @padding-small-horizontal;
        background: #80807B;
        color: #FFF;
    }
    
    .order__listing__item__name {
        font-weight: bold;
        .order__product__options {
            font-weight: normal;
            padding-top: 5px;
            padding-left: @padding-small-horizontal;
        }
    }
    
    .products-total-right {
        width: 100% !important;
        padding-top: 5px;
        padding-bottom: 15px;
    }
    
    #order__sub__total__row td {
        display: block;
    }
    
    div.products-total-row {
        display: block;
        float: none;
        text-align: right;
        
        .prod-total-text {
            float: left;
        }
    }

    .order__listing__item__total__price {
        text-align: right;
        vertical-align: top;
        font-weight: bold;
        color: @brand-primary;
    }
    
    .products-totalvat {
        border-bottom: 1px solid @main-border-color;
        font-weight: bold;
    }
    
    .coupon-container,
    .order-gift-option-container,
    .order-dis-option-container,
    .order-backupcd-option-container {
        background: #F2F2F2;
        padding-left: @padding-small-horizontal;
        padding-right: @padding-small-horizontal;
    }
    
    .order-backupcd-option-container {
        border-top: 1px solid @main-border-color;
    }
    
    // Hide stuff
    .products-content__title,
    
    .order__listing__item__delivery,
    .order__listing__item__qty,
    .order__listing__item__unit__price,
//    .order__listing__item__total__price,
//    .order__listing__item__remove,
    
    .order__checkout__summary tr,
    
    #order__sub__total__row td.products-total-left,
    
    div.products-total-row.products-subtotal.order__sub__total,
    
    #Update--btn {
        display: none;
    }
    
    // Show stuff
    .order__checkout__summary tr#order__sub__total__row,
    .order__checkout__summary tr#order__sub__total__row tr,
    tr.coupon-container,
    tr.coupon-container tr,
    tr.order-gift-option-container,
    tr.order-gift-option-container tr,
    tr.order-dis-option-container,
    tr.order-dis-option-container tr,
    tr.order-backupcd-option-container,
    tr.order-backupcd-option-container tr {
        display: block;
    }
}

.products-content__info {
    color: @product-content-color;
}