#order__coupon__input {
    float: left;
}

#coupon-apply {
    height: @input-height-base; 
    font-size: 22px;
    vertical-align: middle;
    background: none;
    border: none;
    color: @brand-primary;
    &:hover {
        color: lighten(@brand-primary, 10%);
    }
}