.payment-options {
	color: @input-color;
}
.payment-options label {
	margin-bottom: 0;
}
.payment-options .payment-option *,
.payment-options .payment-option {
	cursor: pointer;
	vertical-align: middle;
}
.payment-options .payment-option {
	padding: 5px 10px;
}
.payment-options-select-selected-option {
	background: @input-bg;
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	.box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
	cursor: pointer;
	display: inline-block;
    *display: inline;
    zoom: 1;
	height: @input-height-base;
	line-height: @line-height-base;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	padding: 0 10px;
//	width: 202px;
	cursor: pointer;
}
.payment-options-select-selected-option.focus .select-box-arrow {
	border-left: 7px solid transparent !important;
	border-right: 7px solid transparent !important;
	border-bottom: 7px solid @input-border !important;
	border-top: 0 !important;
    
    &.icon {
        border: 0 solid transparent!important;
    }
}
.payment-options-select-selected-option-label {
	width: 100%;
}
.payment-options-select-selected-option-value {
	background: none !important;
	color: @input-color;
	display: inline-block;
    *display: inline;
    zoom: 1;
	font-size: @font-size-base;
	font-weight: normal;
	height: (@input-height-base - 2) !important;
	line-height: (@input-height-base - 2) !important;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 95%;
	cursor: pointer;
}
.payment-options-select-list {
	display: none;
	background: @input-bg;
	border: 1px solid @input-border;
	border-radius: @input-border-radius;
	.box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
	position: absolute;
	width: auto;
	margin-top: 2px;
	padding-top: 5px;
	z-index: 999999;
    
    overflow: visible !important;
    
    .payment-option {
        visibility: hidden;
        opacity: 0;
    }
    
    &.active .payment-option {
        visibility: visible;
        opacity: 1;
    }
}

.payment-option-title {
	padding-left: 5px;
	font-weight: normal;
}
.payment-options-select .payment-options-select-selected-option-value {
    color: lighten(@input-color, 30%);
}
.payment-options-select.selected .payment-options-select-selected-option-value {
    color: @input-color;
}
.payment-options-select-list input,
.payment-options-select .payment-options-select-selected-option input {
	position: absolute;
	top: -50px;
	left: -50px;
	opacity: 0;
}
.payment-options-sprite-64 .payment-options-select-selected-option {
	height: 64px;
	line-height: 64px;
    .select-box-arrow-wrapper {
    	height: 64px;
    }
}
.payment-options-sprite-48 .payment-options-select-selected-option {
	height: 48px;
	line-height: 48px;
    .select-box-arrow-wrapper {
    	height: 48px;
    }
}
.payment-options-sprite-32 .payment-options-select-selected-option {
	height: 38px;
	line-height: 38px;
    .select-box-arrow-wrapper {
    	height: 38px;
    }
}
body.page-express-payments-checkout .payment-options-sprite-32 .payment-options-select-selected-option-value {
	height: 34px !important;
	line-height: 34px !important;
}
.payment-options-sprite-48 .payment-options-select-selected-option-value {
	height: 43px !important;
	line-height: 43px !important;
}
.payment-options-sprite-64 .payment-options-select-selected-option-value {
	height: 60px !important;
	line-height: 60px !important;
}

.payment-options-card-icons {
    position: absolute;
    right: 0;
    height: 22px;
    display: inline-block;
}

.payment-options-card-icons > img {
	-webkit-filter: grayscale(1); /* Webkit */
    filter: gray; /* IE6-9 */
    filter: grayscale(1); /* W3C */
//    position: absolute;
//    right: 100%;
    margin-top: 6px;
    margin-right: 5px;
    display: none;
}
.payment-options-card-icons > img.active {
	-webkit-filter: none;
    filter: none !important;
    
    display: block;
}
// Hide Error messages on CARD=2
.card__data__form__error {
    display: none !important;
}

#tiCNumber.card-number-input-autodetect {
    background-image: none !important;
}

// Right to left text
body.rtl {
    .payment-options-select-selected-option-value {
        text-align: right;
    }
    
    .payment-options-card-icons {
        left: 0;
        right: auto;
    }
    
    .payment-options-card-icons > img {
//        left: 100%;
//        right: auto;
        margin-left: 5px;
        margin-right: 0;
    }
}

// Media queries
@media screen and (max-width: 500px) {
    .payment-options-select-selected-option-value .payment-option-image {
        display: none;
    }
}

/* START APS-1182 */

.checkout .form-table-billing-payment .card__data__form.mandatory td:first-child {
	 width: 200px;
}

/* END APS-1182 */

/* Show Card Error */
.card-number-error-message {
	display: none;
}
.order__checkout__form__label__error + td .card-number-error-message {
	display: block;
}
/* End Show Card Error */
