/* ==========================================================================
   PRESET 1
   ========================================================================== */

#steps-wrap.preset-1 {
    #steps {
        .step {
            vertical-align: top;
        }
        .step-box-out {
            display: block;
            width: 20px;
            margin: 0 auto;
            padding: 0 10px;
            box-sizing: content-box;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            background: @cart-steps-bg;
        }
        .step-box {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-clip: padding-box;
            background: @cart-steps-active-color;
        }
        .step-box-in {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-clip: padding-box;
            background: @cart-steps-bg;
        }
        .step-nr-wrapper {
            display: none;
        }
        .step-nr-text {
            font-weight: normal;
        }
        .step.checked .step-icon {
            display: inline-block;
            padding: 4px 0;
        }
        .step.checked .step-nr-text {
            display: none;
        }
        .step-name {
            background: transparent;
            padding-right: 0;
            padding-bottom: 10px;
        }
        .step-name.on-bottom {
            padding-top: 10px;
            padding-bottom: 0;
        }
    }
}

#steps-progress-bar-wrap.preset-1 {
    top: auto;
    bottom: 17px;
    #steps-progress-bar-content {
        .step-bar-left-content,
        .step-bar-right-content {
            border-top-width: 1px;
            border-bottom-width: 1px;
        }
    }
}

#steps-progress-bar-wrap.preset-1.labels-on-bottom {
    top: 10px;
    bottom: auto;
}

@media screen and (max-width: 479px) {
    #steps-wrap.preset-1 #steps .step {
        display: table-cell;
    }
}