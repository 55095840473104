
.check-marks {
    .order__text__field__mandatory.order__text__field__error, .order__text__field.order__text__field__error,
    .valid.order__text__field.order__text__field__error, .valid.order__text__field__mandatory.order__text__field__error, 
    .valid {
        background-image: none !important;
    }

    .validate-sign {
        width: 20px;
        height: 20px;
        // float: left;
        // top: 10px;
        margin-left: 3px;
        // margin-top: 2%;
        display: inline-block;
        position: absolute;
    }
    .validate-sign.error {
        background-image: url('@{resources-domain}/images/merchant/a25e0e62a4702353f399953579424997/cross.png') !important;
        // margin-top: 3%;
    }
    .validate-sign.valid {
        background-image: url('@{resources-domain}/images/merchant/a25e0e62a4702353f399953579424997/check.png') !important;
        border: none !important;
        // margin-top: 3%;
    }

    #tiCVV-validate-sign {
        margin-left: 20px;
    }
    .order__checkout__form__input,
    .select-box.valid-select,
    #order__checkout__billing__info__content__tbody tr td+td,
    #credit__cart__fields__container tr td+td {
        position: relative;
    }

    #cbExpMounth-validate-sign,
    #cbExpYear-validate-sign,
    .icon.icon-cd,
    #state-validate-sign,
    #billingcountry-validate-sign,
    #billing_currency-validate-sign,
    #bank_issuer-validate-sign {
        display: none;
    }

    #tiCVV_error, 
    #nameoncard_error {
        display: none !important;
    }
}
body.layout-0.check-marks {
    .validate-sign {
        margin-top: 3% !important;
    }
}

body.layout-1.check-marks,
body.layout-2.check-marks {
    .validate-sign {
        margin-top: 2% !important;
    }
}

body.layout-2.check-marks {
    #credit__cart__fields__container {
        .validate-sign {
            margin-top: 3% !important;
        }
    }
}
body.layout-5.check-marks,
body.layout-6.check-marks {
    .validate-sign {
        margin-top: 3.3% !important;
    }
}

body.layout-5.check-marks {
    .validate-sign {
        margin-left: 0px;
    }
}