.remove-product-button {
    cursor: pointer;
}

.remove-product-button.img {
    background: url("@{resources-domain}/images/merchant/3cba81c5c6cac4ce77157631fc2dc277/delete-product-initial-state.png") no-repeat scroll center top rgba(0, 0, 0, 0);
    height: 17px;
    width: 12px;
    
    &:hover {
        background: url("@{resources-domain}/images/merchant/aff1621254f7c1be92f64550478c56e6/delete-product.png") no-repeat scroll center top rgba(0, 0, 0, 0);
    }
}

.remove-product-button.text {
    font-weight: bold;
    font-size: 120%;
    opacity: .65;
    filter: alpha(opacity=65);
    
    &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
        color: red;
    }
}