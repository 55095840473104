//
// Forms
// --------------------------------------------------
// Normalize non-controls
//
// Restyle and baseline non-control form elements.
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}
legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: @line-height-computed;
    font-size: (@font-size-base * 1.5);
    line-height: inherit;
    color: @legend-color;
    border: 0;
    border-bottom: 1px solid @legend-border-color;
}
input[type="search"] {
    .box-sizing(border-box);
}
// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
    margin: 0;
    margin-top: 1px \9; // IE8-9
    line-height: normal;
}
// Set the height of file controls to match text inputs
input[type="file"] {
    display: block;
}
// Make range inputs behave like textual form controls
input[type="range"] {
    display: block;
    width: 100%;
}
// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}
// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    .tab-focus();
}
// Adjust output element
output {
    display: block;
    padding-top: (@padding-base-vertical + 1);
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
}
// Common form controls
select,
textarea,
input[type="text"],
input[type="number"],
input[type="password"],
input.order__text__field,
textarea.order__textarea__field,
select.order__select__field,
.register_form_table .register_form_text_input {
    display: inline-block;
    height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @input-color;
    background-color: @input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid @input-border;
    border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    .box-shadow(@input-box-shadow);
    .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
    // Customize the `:focus` state to imitate native WebKit styles.
    .form-control-focus();
    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled], &[readonly], fieldset[disabled] & {
        background-color: @input-bg-disabled;
        opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
    }
    &[disabled],
    fieldset[disabled] & {
        cursor: @cursor-disabled;
    }
    // Reset height for `textarea`s
    textarea& {
        height: auto;
    }
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: lighten(@input-color, 30%) !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: lighten(@input-color, 30%) !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: lighten(@input-color, 30%) !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: lighten(@input-color, 30%) !important;
}

body.sign-up .signup-form {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input.order__text__field,
    textarea.order__textarea__field,
    select.order__select__field {
        display: inline-block;
        height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
        padding: @padding-base-vertical @padding-base-horizontal;
        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @input-color;
        background-color: @input-bg;
        background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
        border: 1px solid @input-border;
        border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
        .box-shadow(@input-box-shadow);
        .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
        // Customize the `:focus` state to imitate native WebKit styles.
        .form-control-focus();
        // Disabled and read-only inputs
        //
        // HTML5 says that controls under a fieldset > legend:first-child won't be
        // disabled if the fieldset is disabled. Due to implementation difficulty, we
        // don't honor that edge case; we style them as disabled anyway.
        &[disabled], &[readonly], fieldset[disabled] & {
            background-color: @input-bg-disabled;
            opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
        }
        &[disabled],
        fieldset[disabled] & {
            cursor: @cursor-disabled;
        }
        // Reset height for `textarea`s
        textarea& {
            height: auto;
        }
    }
}

select:focus, textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input.order__text__field:focus, textarea.order__textarea__field:focus, select.order__select__field:focus {
    box-shadow: @input-box-shadow-focus;
    -webkit-box-shadow: @input-box-shadow-focus;
}
// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    label {
        min-height: @line-height-computed; // Ensure the input doesn't jump when there is no text
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
    }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px;
}
.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}
// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px; // space out consecutive inline controls
}
// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"] {
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
        cursor: @cursor-disabled;
}
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
    &.disabled,
    fieldset[disabled] & {
        cursor: @cursor-disabled;
}
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
    &.disabled,
    fieldset[disabled] & {
        label {
            cursor: @cursor-disabled;
    }
}
}

table.table-exp-dates td,
#fcode td,
#bill_phone td {
    padding-bottom: 0;
}

#cbExpMounth-select-box {
    margin-right: 5px;
}

.ie-7 input[type="text"], .ie-8 input[type="text"],
.ie-7 input[type="password"], .ie-8 input[type="password"],
.ie-7 input.order__text__field, .ie-8 input.order__text__field,
.ie-7 textarea.order__textarea__field, .ie-8 textarea.order__textarea__field,
.ie-7 select.order__select__field, .ie-8 select.order__select__field {
    padding-top: 0;
    padding-bottom: 0;
}

.info-link {
    line-height: @input-height-base;
}

// Right to left text
body.rtl {
    #cbExpMounth-select-box {
        margin-left: 5px;
        margin-right: 0;
    }
}