.order__box .order__box__title .order__box__aux2 .heading-bullet {
    content: '1';
    background-color: @brand-primary;
    border-radius: 50%;
    /* padding: 10px; */
    width: 30px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    color: #FFF;
    padding: 2px 0;
    font-size: 18px;
    margin-right: 3px;
}