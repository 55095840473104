/* MAIN
   -------------------------------- */
#main {
    padding-bottom: @main-padding;
}

#order__container {
    background: @main-bg;
    padding: @main-padding @main-padding 0;
    margin: @main-margin;
    border: @main-border;
    border-radius: @main-border-radius;
}

body.verify #order__container,
body.finish #order__container {
    padding: 20px 10px 0;
}

body.empty-cart #order__container {
    padding: 20px 25px 0;
}

body.page-support #order__container {
    padding: 15px 25px 25px;
}

body.product #order__container {
    padding: 15px 25px 0;
}

#order__header {
    margin-top: @main-header-margin - @main-padding;
    margin-bottom: @main-header-margin;
}

span.order__help_billing {
    padding-bottom: @table-cell-padding;
}

/* CUSTOM STYLES
   -------------------------------- */
#company_radio {
    margin-left: 5px;
}

.rtl #company_radio {
    margin-left: 0;
    margin-right: 5px;
}

#order__email__marketing__signup label {
    font-size: 12px !important;
    margin-left: 5px;
}

/**
    APS-2882 
*/
body.layout-6 {
    #credit__cart__fields__container tr td:first-child {
        width: 150px !important;
    }
    #credit__cart__fields__container {
        :not(#tiCVV-validate-sign) {
            margin-left: -1px;
        }
    }
}

body.layout-8 {
    .amex-express {
        float: right;
        margin-top: -6px;
    }
    #order__checkout__autofill__data {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: @screen-xs-max) {
  #order__checkout__autofill__data {
    .order__box__content {
      .order__box__aux2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .paypal-express {
          padding-right: 0;
        }
      }
    }
  }
}
