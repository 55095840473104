/* ==========================================================================
   PRESET 3
   ========================================================================== */

#steps-wrap.preset-3 {
    #steps {
        .step {
            vertical-align: top;
        }
        .step-box-out {
            display: block;
            width: 24px;
            margin: 0 auto;
            box-sizing: content-box;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
        }
        .step-box {
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-clip: padding-box;
        }
        .step-box-in {
            position: absolute;
            top: 15px;
            left: 5px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-clip: padding-box;
            background: @cart-steps-inactive-color;
        }
        .step.checked .step-box-in,
        .step.current .step-box-in {
            background: @cart-steps-active-color;
        }
        .step-nr-wrapper {
            display: none;
        }
        .step-nr-text {
            font-weight: normal;
        }
        .step.checked .step-icon {
            display: inline-block;
            padding: 4px 0;
        }
        .step.checked .step-nr-text {
            display: none;
        }
        .step-name {
            background: transparent;
            padding-right: 0;
            padding-bottom: 10px;
        }
        .step-name.on-bottom {
            padding-top: 10px;
            padding-bottom: 0;
        }
    }
}

#steps-progress-bar-wrap.preset-3 {
    top: auto;
    bottom: 16px;
    #steps-progress-bar-content {
        .step-bar-left-content,
        .step-bar-right-content {
            border-top-width: 1px;
            border-bottom-width: 1px;
        }
    }
}

#steps-progress-bar-wrap.preset-3.labels-on-bottom {
    top: 22px;
    bottom: auto;
}

@media screen and (max-width: 479px) {
    #steps-wrap.preset-3 #steps .step {
        display: table-cell;
    }
}