.select-box {
    text-align: left;
    background: @input-bg;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
    .box-shadow(@input-box-shadow);
    cursor: pointer;
    display: inline-block;
    height: @input-height-base;
    line-height: @line-height-base;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    padding: 0 10px;
    width: 202px;
}
.select-box select.force-reset-select {
    width: 100% !important;
    background: none !important;
    filter: alpha(opacity=0) !important;
    border: 0 !important;
    outline: none !important;
    opacity: 0 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
    cursor: pointer;
}
.select-box.order__select__focus {
    background-color: #fff;
}
.select-box.order__select__field__error {
    background-color: lighten(@brand-danger, 40%) !important;
    border-color: @brand-danger !important;
}
.select-box span.selected-option {
    background: none !important;
    color: @input-color;
    display: inline-block;
    font-size: @font-size-base;
    font-weight: normal;
    height: @input-height-base !important;
    line-height: @input-height-base !important;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
    cursor: pointer;
}
.select-box span.selected-option.placeholder-color {
    color: lighten(@input-color, 30%);
}

.select-box.order__select__field__error span.selected-option {
    color: @brand-danger;
}

.select-box-arrow-wrapper {
    height: @input-height-base;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    cursor: pointer;
}
.sprite-48 .select-box-arrow-wrapper {
    height: 48px;
}
.sprite-64 .select-box-arrow-wrapper {
    height: 64px;
}
.select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid @input-border;
    height: 0;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -4px;
    width: 0;
    cursor: pointer;
    
    &.icon {
        border: 0px solid transparent !important;
        top: 50%;
        left: auto;
        right: auto;
        width: 100%;
        text-align: center;
        height: auto;
        transform: translate(0, -50%);
        display: block;
        margin: 0;
        font-size: 20px;
        color: @input-border;
    }
}
.select-box.order__select__field__error .select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid @brand-danger;
    
    &.icon {
        color: @brand-danger;
    }
}

.select-box.valid-select .select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid @brand-success;
    
    &.icon {
        color: @brand-success;
    }
}
.select-box.order__select__focus .select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @input-border;
    border-top: 0;
    
    &.icon {
        color: @input-border;
    }
}
.select-box.order__select__focus.valid-select .select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @brand-success;
    border-top: 0;
    
    &.icon {
        color: @brand-success;
    }
}
.select-box.order__select__focus.order__select__field__error .select-box-arrow {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @brand-danger;
    border-top: 0;
    
    &.icon {
        color: @brand-danger;
    }
}

// Custom Classes
.select-box.no-box-select,
.no-box-select .select-box {
    background: transparent;
    border: 1px solid transparent;
    box-shadow: 0 0 0 transparent;
}

// Right to left text
body.rtl {
    .select-box,
    .select-box span.selected-option {
        text-align: right;
    }
    
    .select-box-arrow-wrapper {
        left: 0;
        right: auto;
    }
}