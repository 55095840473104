html {
    box-sizing: border-box;
    height: 100%;
}
body {
    font-family: @font-family-base;
    background: @body-bg;
    font-size: @font-size-base !important;
    line-height: @line-height-computed !important;
    color: @text-color;
    min-height: 100%;
}
*,
*:before, *:after {
    box-sizing: inherit;
}
*:before, *:after {
    box-sizing: border-box;
}
::selection {
    background: @link-hover-color;
    color: #fff;
}
::-moz-selection {
    background: @link-hover-color;
    color: #fff;
}
a:link, a:visited, a:hover, a:active {
    color: @link-color;
    text-decoration: none;
}
a:hover {
    text-decoration: @link-hover-decoration;
}
.display-none {
	display: none;
}
.display-block {
    display: block;
}
::-ms-clear { display: none; }

/* Ninja mode, does what it supposed to do, but you can't see it :) */
.ninja {
	display: block !important;
	width: 0 !important;
	height: 0 !important;
	opacity: 0 !important;
	position: absolute !important;
	z-index: -1 !important;
}
label{
    display: inline-block;
}
label img{
    pointer-events: none;
}

.placeholderSpan label {
    color: @input-color-placeholder !important;
}
