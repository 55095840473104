/* ==========================================================================
   HIDE BACKEND CODE
   ========================================================================== */
#order__cross__sell__content,
#order__crossselling {
  display: none;
}

/* ==========================================================================
   CROSS SELLING
   ========================================================================== */
#x-sell.order__box .order__box__content .order__box__aux2 {
  padding: 0;
}

.x-sell-img {
  margin-right: 5px;
}

.x-sell-prod {
  position: relative;
}

.x-sel-prod-discount,
.x-sel-prod-discount-box {
  position: absolute;
  top: 0;
  right: 0;
  float: right;
}

.x-sel-prod-discount-box {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 55px 55px 0;
  border-color: transparent @brand-primary transparent transparent;
}

.x-sel-prod-discount-value {
  margin-top: 10px;
  margin-right: 1px;
  position: relative;
  font-size: 85%;
  color: #FFF;
}

.x-sell-prod-link:hover {
  text-decoration: none;
}

.x-sell-product-title {
  font-weight: bold;
  display: inline-block;
}

.x-sell-prod-prices {
  text-align: center;

  .old-price,
  .new-price {
    display: inline-block;
  }
  .new-price {
    font-weight: bold;
  }
  .old-price {
    text-decoration: line-through;
  }
}

#x-sell .btn .icon {
  font-size: 200%;
  line-height: 100%;
  font-weight: normal;
}

#x-sell .btn .btn-text {
  position: relative;
  top: -3px;
}

/* ==========================================================================
   CROSS SELLING
   ========================================================================== */
.x-sell-product-description-popup {
  position: relative;
  display: inline-block;

  .icon {
    color: @x-sell-popup-tooltip-color;
    padding-left: 5px;
    padding-right: 5px;
  }
  &:hover .icon {
    color: @x-sell-popup-tooltip-hover-color;
    cursor: help;
  }
}

.x-sell-product-description-popup .x-sell-product-description {
  text-align: left;
  width: @x-sell-popup-width;
  position: absolute;
  top: 0;
  left: 100%;
  border: @x-sell-popup-border;
  border-radius: @box-border-radius;
  z-index: 90;
  background: @x-sell-popup-bg;
  padding: @x-sell-popup-padding;
  display: none;
  box-shadow: @x-sell-popup-box-shadow;
}

.rtl .x-sell-product-description-popup .x-sell-product-description {
  text-align: right;
}

.x-sell-product-description-popup:hover .x-sell-product-description {
  display: block;
}

.btn.add-cross-sell {
  font-size: @x-sell-btn-font-size;
  padding: @x-sell-btn-padding;
  line-height: 1;
}

.x-sell-products {
  margin-bottom: 0;
}

/* ==========================================================================
   DISPLAY HORIZONTAL
   ========================================================================== */
#x-sell.display-horizontal {
  .x-sell-products {
    display: table;
    width: 100%;
  }
  .x-sell-prod {
    display: table-row;
    border-top: @x-sell-product-border;
  }
  .x-sell-prod.first {
    border-top: 0px solid transparent;
  }
  .x-sell-prod-content,
  .x-sell-prod-prices,
  .x-sell-prod-add {
    display: table-cell;
    padding: @x-sell-product-padding;
  }
  .x-sell-prod-prices {
    position: relative;
    border-left: @x-sell-product-border;
    border-right: @x-sell-product-border;
    vertical-align: middle;
  }
  .x-sell-prod-add {
    text-align: center;
  }
}

.ie-7 #x-sell.display-horizontal .x-sell-products .x-sell-prod-content,
.ie-7 #x-sell.display-horizontal .x-sell-prod-prices {
  float: left;
}

.ie-7 #x-sell.display-horizontal .x-sell-prod-content {
  width: 50%;
}

.ie-7 #x-sell.display-horizontal .x-sell-prod-prices {
  width: 30%;
  padding: 10px 0;
}

.ie-7 #x-sell.display-horizontal .x-sell-prod-prices {
  width: 30%;
  padding: 35px 0 0 0;
}

.ie-7 #x-sell.display-horizontal .x-sell-prod-add {
  padding-top: 30px;
}

/* ==========================================================================
   DISPLAY VERTICAL
   ========================================================================== */
#x-sell.display-vertical, #x-sell.condensed {
  .x-sell-products {
    width: 100%;
  }
  .x-sell-prod {
    float: left;
    text-align: center;
    margin-top: -1px;
    border-top: @x-sell-product-border;
    border-right: @x-sell-product-border;
  }
  .x-sell-prod.last
    //.x-sell-prod-content,
    //.x-sell-prod.last .x-sell-prod-prices,
    //.x-sell-prod.last .x-sell-prod-add 
  {
    border-right: 0px solid transparent;
  }
  .x-sell-prod.last .x-sell-product-description-popup .x-sell-product-description {
    right: 100%;
  }
  .x-sell-prod-content,
  .x-sell-prod-prices,
  .x-sell-prod-add {
    padding: @x-sell-product-padding;
    //border-right: @x-sell-product-border;
  }

  .x-sell-prod-content,
  .x-sell-prod-prices {
    text-align: left;
  }
  .x-sell-prod-add {
    text-align: right;
  }
}

#x-sell.display-vertical.cols-1 .x-sell-prod, #x-sell.condensed .x-sell-prod {
  width: 100%;
}

#x-sell.display-vertical.cols-2 .x-sell-prod {
  width: 50%;
}

#x-sell.display-vertical.cols-3 .x-sell-prod {
  width: 33.333333%;
}

#x-sell.display-vertical.cols-4 .x-sell-prod {
  width: 25%;
}

/* ==========================================================================
   RIGHT TO LEFT TEXT
   ========================================================================== */
.rtl {
  .x-sel-prod-discount,
  .x-sel-prod-discount-box {
    right: auto;
    left: 0;
    float: right;
  }

  .x-sel-prod-discount-box {
    border-width: 0 0 55px 55px;
    border-color: transparent transparent transparent @brand-primary;
  }

  .x-sel-prod-discount-value {
    margin-top: 10px;
    margin-left: 1px;
  }

  #x-sell.display-horizontal .x-sell-prod .x-sell-product-description-popup .x-sell-product-description {
    left: auto;
    right: 100%;
  }

  #x-sell.display-vertical .x-sell-prod.first .x-sell-product-description-popup .x-sell-product-description {
    left: 100%;
    right: auto;
  }

  #x-sell.display-vertical .x-sell-prod.last .x-sell-product-description-popup .x-sell-product-description {
    left: auto;
    right: 100%;
  }
}

/* ==========================================================================
   BROWSER COMPATIBILITY
   ========================================================================== */
.ie-7 #x-sell.display-horizontal .x-sell-prod-prices {
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

/* ==========================================================================
   MEDIA QUERIES
   ========================================================================== */
@media screen and (max-width: 599px) {
  #x-sell.display-horizontal {
    .x-sell-products {
      width: 100%;
      display: block;
    }
    .x-sell-prod {
      display: block;
      width: 100%;
      float: none;
      text-align: center;
      margin-top: -1px;
      border-top: @x-sell-product-border;
    }
    .x-sell-prod.last {
      border-right: 0px solid transparent;
    }
    .x-sell-prod.last .x-sell-product-description-popup .x-sell-product-description {
      right: 100%;
    }
    .x-sell-prod-content,
    .x-sell-prod-prices,
    .x-sell-prod-add {
      display: block;
      padding: @x-sell-product-padding;
      //border-right: @x-sell-product-border;
    }
    .x-sell-prod-content,
    .x-sell-prod-prices {
      text-align: left;
    }
    .x-sell-prod-prices {
      position: static;
    }
    .x-sell-prod-add {
      text-align: right;
    }
  }
}

@media screen and (min-width: 350px) {
  #x-sell.condensed {
    .x-sell-prod {
      .clearfix();
      border-right: 0px solid transparent;
    }
    .x-sell-prod-prices,
    .x-sell-prod-add {
      width: 50%;
      float: left;
    }
    .x-sell-prod-content,
    .x-sell-prod-prices,
    .x-sell-prod-add {
      border-left: 0px solid transparent;
      //  border-right: 0px solid transparent;
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 599px) {
  #x-sell.display-horizontal {
    .x-sell-prod {
      .clearfix();
    }
    .x-sell-prod-prices,
    .x-sell-prod-add {
      width: 50%;
      float: left;
    }
    .x-sell-prod-content,
    .x-sell-prod-prices,
    .x-sell-prod-add {
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;
    }
  }
}

/* ==========================================================================
   STYLES
   ========================================================================== */
/* ======================== CLEAN STYLE ============================== */
#x-sell.clean {
  // Styling only when the cross sell is vertical
  &.display-vertical {
    .cross__selling__content.x-sell-products {
      .clearfix();
      text-align: left;
      //    padding: 10px!important;
      .x-sell-prod {
        border-top: none !important;
        .x-sell-prod-content {
          // padding: 0px;
          .x-sell-prod-text {
            padding-bottom: 10px;
          }
          .x-sell-prod-prices {
            padding: 0 5px 0 0;
            margin-bottom: 10px;
          }
          .x-sell-prod-add {
            margin-bottom: 10px;
            padding: 0px;
          }
        }
      }
    }
  }
  &.display-horizontal {
    .cross__selling__content.x-sell-products {
      .x-sell-prod-content,
      .x-sell-prod-prices {
        border: none !important;
        vertical-align: middle;
      }
      .x-sell-prod-add {
        // vertical-align: middle;
        text-align: right;
      }
      .x-sell-img-wrapper,
      .x-sell-prod-info {
        display: inline-block;
        vertical-align: top;
      }
      .x-sell-prod-prices,
      .x-sell-prod-add {
        display: table-cell;
        padding-left: 0;
        width: auto;
      }
    }
  }
  .add-cross-sell {
    //padding: 10px 16px;
    font-weight: normal;
    &:hover {
      color: #FFF;
    }
    //.btn-text {
    //  top: 0;
    //}
  }
  // General styling for cross sell
  .x-sell-prod-prices {
    .old-price {
      color: @gray-light;
    }
    .new-price {
      margin-right: 5px;
    }
  }
  .x-sell-product-title {
    color: @text-color;
  }
}

@media screen and ( max-width: @screen-xs-max ) {
  #x-sell.clean {
    // Styling only when the cross sell is vertical
    &.display-vertical {
      ul.cross__selling__content.x-sell-products {
        padding: 0 10px !important;
        .x-sell-prod {
          height: auto !important;
          display: block !important;
          width: 100% !important;
          &:not(:last-child) {
            border-right: none;
            border-bottom: 1px solid @main-border-color;
          }
          .x-sell-prod-content {
            padding: 10px 0;
            .x-sell-prod-prices,
            .x-sell-prod-add {
              bottom: 0;
            }
            .x-sell-prod-prices {
              float: left;
            }
            .x-sell-prod-add {
              float: right;
            }
          }
        }
      }
    }
    /* General Styling for x-sell for small devices */
    ul.cross__selling__content.x-sell-products {
      padding: 0 10px;
    }
  }
}

@media screen and ( min-width: @screen-sm-min ) {
  /* Cross Sell for medium and large devices */
  #x-sell.clean {
    // Styling only when the cross sell is vertical
    &.display-vertical {
      .cross__selling__content.x-sell-products {
        padding: 10px 0 !important;
        .x-sell-prod {
          //text-align: left;
          .x-sell-prod-content {
            .x-sell-img-wrapper,
            .x-sell-prod-info {
              display: table-cell;
              vertical-align: top;
            }
            .x-sell-img {
              max-width: 60px;
              vertical-align: top;
            }
            .x-sell-prod-link {
              vertical-align: top;
              .x-sell-product-title {
                // max-width: 185px;
              }
            }
          }
          .x-sell-prod-prices,
          .x-sell-prod-add {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
    // Styling only when the cross sell is vertical
    &.display-horizontal {
      .cross__selling__content.x-sell-products {
        padding: 0 10px;
        display: block;
      }
      .x-sell-prod {
        width: 100%;
        display: table;
        .x-sell-prod-content {
          // max-width: 60%;
          // width: 100%;
          padding: 10px 0;
          .x-sell-img-wrapper,
          .x-sell-prod-info {
            display: table-cell !important;
            float: left;
          }
          .x-sell-prod-info {
            width: 100%;
          }
          .x-sell-img-wrapper {
            width: 12%;
          }
          .x-sell-img-wrapper + .x-sell-prod-info {
            width: 88%;
            .x-sell-prod-prices {
              padding-left: 0;
            }
          }
          .x-sell-prod-text,
          .x-sell-prod-prices,
          .x-sell-prod-add {
            float: left;
            display: table-cell;
          }
          .x-sell-prod-text {
            width: 50%;
          }
          .x-sell-prod-prices {
            width: 30%;
            padding-left: 8%;
            .old-price {
              // display: block;
            }
          }
          .x-sell-prod-add {
            width: 20%;
          }
        }
      }
    }
    // General styling for cross sell
    .x-sell-prod-add {
      text-align: left;
    }
  }
}

@media screen and ( max-width: 1570px) {
  #x-sell.clean {
    .x-sell-prod.last {
      .x-sell-product-description {
        left: unset !important;
      }
    }
  }
}

&.rtl {
  #x-sell.clean {
    &.condensed .x-sell-prod-content,
    &.condensed .x-sell-prod-prices,
    &.display-vertical .x-sell-prod-content,
    &.display-vertical .x-sell-prod-prices {
      text-align: right;
    }
  }
}