body {
    /* the following line fixes a blink in chrome https://code.google.com/p/chromium/issues/detail?id=108025 */
    -webkit-backface-visibility: hidden;
}

.floating-label-wrapper {
    position: relative;
    display: block;
    
    .floating-label {
        position:absolute;
        top:0px;
        left:0;
        font-size:@floating-label-font-size;
        color:@floating-label-color;
        transition: all 0.1s linear;
        -webkit-transition-delay: 0.1s; /* Safari */
        transition-delay: 0s;
        opacity:0;
        display: block !important;
        padding-bottom: 0 !important;
        
        &.active {
            color:@floating-label-active-color;
        }
        
        &.visible,
        &.always-visible {
            transition-delay: 0.1s;
            top: @floating-label-top;
            opacity:1;
            
            & + .order__checkout__form__input,
            & + .order__checkout__form__input__error,
            & + td {
                padding-top: @floating-label-top-spacing;
                transition: all 0.1s linear;
            }
        }
    }
    
    .floating-label,
    .floating-label span {
        font-weight:@floating-label-font-weight !important;
    }
    
    .order__checkout__form__label,
    .order__checkout__form__label__error,
    .order__checkout__form__input,
    .order__checkout__form__input__error,
    .floating-label + td {
        display: block;
        width: 100%;
    }
    
    #nameoncard, #tiCNumber, #tiCVV {
        float: none;
    }
}

#card__data__nr .floating-label {
    position: absolute;
}

table.form-table-billing .floating-label-wrapper .text {
    float: none;
    
    &#fiscalcode {
        float: left;
    }
}