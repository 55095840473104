body.checkout {
//    br,
    .order__checkout__summary td:empty,
    .order__checkout__billing td:empty {
        display: none !important;
    }
    
    .order__checkout__summary > tbody > tr.last-spaced-row > td,
    .order__checkout__billing tbody > tr.last-spaced-row > td,
    .order__checkout__addfields__content > tbody > tr.last-spaced-row > td {
        padding-bottom: 0;
    }
    
    #order__autorenewal__container {
        margin-bottom: 0;
    }
    
    .order__finish__terms__and__conds__agreement {
        margin-bottom: @table-cell-padding;
    }
}

body.checkout:not(.page-express-payments-checkout) {
    .order__checkout__billing > tbody > tr > td {
        padding-bottom: 0;
    }
}