/* Forms*/
label {
    margin-bottom: 0;
}

input, input[type="text"] {
    width: auto;
}

body.upgrade br {
    display: none;
}

body > a.trust_logo_link,
body > a[href*="mcafeesecure.com"] {
    display: none;
}

.order__checkout__form__label {
    padding-right: 5px;
}

.order__checkout__form__input,
#card__data__nr td {
    position: relative;
}

/* Product Page */
.order__product__title {
    display: none;
}

ul.order__products .order__box .order__box__content .order__box__aux2 .order__product__image {
    margin-bottom: 25px;
    margin-right: 25px;
}

div.order__product__qty input {
    width: auto;
}

.order__listing__row.order__product__bundle__contents_product td.order__listing__item__name, .order__listing__row.order__product__bundle__contents_title td.order__listing__item__name {
    padding-left: 20px;
    font-weight: normal !important;
    font-style: italic !important;
}

.order__product__options li[id^="price__option__group"] {
    margin-bottom: 10px;
}

ul.order__product__attributes {
    margin-left: 0;
}

.order__product__form {
    display: table;
}

table.form-table-userinfo span.order__checkout__form__mandatory, #req_state_label, label {
    font-weight: normal;
}


/* Order Container */
#order__container {
    width: 100%;
}


/* Order Box */
.order__box {
    border: @box-border;
    border-radius: @box-border-radius;
    background-clip: padding-box;
    box-shadow: @box-shadow;
    clear: both;
    display: block;
    margin-bottom: @box-margin-bottom;
    margin-top: 0;
}

.order__box .order__box__title {
    background-color: @box-header-bg;
    border-bottom: @box-border;
    border-radius: @box-border-radius @box-border-radius 0 0;
    background-clip: padding-box;
}

.order__box .order__box__title .order__box__aux2 {
    font-size: @font-size-large;
    color: @box-header-color;
    font-weight: @box-header-font-weight;
    padding: @box-header-padding;
}

.order__box .order__box__content {
    background: @box-content-bg;
    display: block;
    line-height: @line-height-small;
    font-size: @font-size-base;
    border-radius: 0 0 @box-border-radius @box-border-radius;
    background-clip: padding-box;
}

.order__box .order__box__content .order__box__aux2 {
    padding: @box-content-padding;
}

.order__box .order__box__footer {
    clear: both;
    display: table;
    width: 100%;
    border-radius: 0 0 @box-border-radius @box-border-radius;
    background-clip: padding-box;
}

#cross__sell__finish__confirm #crossSellModalFormTable td.cs__new_order__image__container {
    padding-right: @padding-small-horizontal;
}

#order__finalTotalPrice {
    margin-bottom: @box-margin-bottom;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.tooltip {
    opacity: 1;
    filter: alpha(opacity=100);
    position: static;
    display: inline;
    font-size: 100%;
}

.order__finish__td__cc #order__autorenewal__container, .order__finish__td__cc .order__finish__button__box {
    margin-left: 0;
    text-align: center;
}

#order__autorenewal, #order__autorenewal__text {
    display: inline-block;
}

#order__autorenewal__text .tooltip {
    display: inline;
}

#order__ccprocess__amount td {
    font-size: 100%;
}

.order__listing__item__total__price .order__billing__price {
    font-size: 100%;
}

#Update, #Update--btn {
    display: none;
}

.label, a.label:hover, a.label:focus {
    color: @text-color;
    font-size: 100%;
}

#order__secure__seal {
    display: none;
}

div.products-total-row {
    display: block;
    float: none;
    text-align: right;
}

body.checkout.page-trial div.products-total-row .order__display__total {
    white-space: initial;
}

.order__listing__header__remove {
    display: none;
}

div.order__checkout__button__container {
    margin-bottom: @box-margin-bottom;
}

.order__product__discount .order__listing__item__name {
    padding-left: @table-cell-padding;
}

td.order__listing__item__name, td.order__listing__item__qty, td.order__listing__item__delivery, td.order__listing__item__unit__price, td.order__listing__item__total__price, td.order__listing__item__remove {
    padding-bottom: @table-cell-padding;
}

.order__product__discount {
    td.order__listing__item__name, td.order__listing__item__qty, td.order__listing__item__delivery, td.order__listing__item__unit__price, td.order__listing__item__total__price, td.order__listing__item__remove {
        padding-top: @table-cell-padding;
    }
    td.order__listing__item__name {
        vertical-align: middle;
    }
    .remove-product {
        display: none;
    }
}

table.order__backupcd__option,
table.order__dis__option {
    margin-top: 0;
    margin-bottom: 0;
}

.sprite-48.pay_opt_4 {
    margin-top: 4px;
}

div.wrapper-exp-selects {
    width: auto;
}

.form-table-verify .helpClass {
    padding-left: @padding-small-horizontal;
    display: inline-block;
}

.form-table-userinfo.form-table-delivery {
    margin: 0 auto;
    max-width: 500px;
}

table.form-table-userinfo td, .order__checkout__addfields__content td, .back-to-shopping-wrapper td, table.order__checkout__billing td {
    padding-bottom: @table-cell-padding;
}

.order__text__label__mandatory {
    font-weight: normal;
}

.order__checkout__form__fiscal__code .order__checkout__form__label {
    vertical-align: top;
    line-height: @input-height-base;
}

.order__help_billing.billing-content__reqtext {
    display: none;
}

.info-link {
    margin-left: 0;
}

table.order__gift__option td {
    vertical-align: baseline !important;
}

#tiCNumber_error {
    display: none !important;
}

#tiCVV_error {
    position: absolute;
    left: 175px;
    padding: @padding-base-vertical 0;
}


/*https://redmine.avangate.com/issues/15744*/
#card__data__nr .field-helper {
    display: none;
}

/*https://redmine.avangate.com/issues/15798*/

.sprite.order__dis__icon,
.order__backupcd__icon.sprite {
     display: none;
}

.order__listing__item__name__backupcd .products-content__info,
.order__listing__item__name__dis .products-content__info {
    display: inline;
}

.products-content__info .icon {
    margin-right: 10px;
    vertical-align: middle;
}

.order__offline__finish {
    margin-bottom: @box-margin-bottom;
    text-align: center;
}

#fiscalcode {
    width: 226px;
    margin-right: @padding-xs-horizontal;
    @media screen and (max-width: 479px) {
        width: 100%;
        margin-right: 0;
    }
}

#tiCVV {
    max-width: 150px;
    width: 100%;
}

#order__email__marketing__signup label {
    display: inline !important;
}
#order__checkout__billing__info__content__tbody #order__email__marketing__signup  td:first-child {
    display:  none !important;
}

/* POPUPS */
#order__container__overlay__loader {
    z-index: 9999;
}

.ui-dialog .ui-dialog-titlebar-close {
    position: static;
    width: auto;
    height: auto;
    float: right;
    margin-right: .3em;
    margin-top: 0;
    padding: 10px;
    background: #F4F4F4;
    border: 0px solid transparent;
    
    &:hover {
        padding: 10px;
        background: #DDD;
        border: 0px solid transparent;
    }
    &.ui-state-focus{
        padding: 10px;
    }
}

/* ADDITIONAL FIELDS */
.order__checkout__addfields__content input[type="checkbox"] {
    vertical-align: middle;
}

.order__help_billing.billing-content__reqtext {
    display: none !important;
}

.page-express-payments-checkout #credit__cart__fields__container tr.card__data__form:first-child {
    display: none !important;
}

/* VERIFY PAGE */
/*https://redmine.avangate.com/issues/15810*/
.submit-paynearme {
    margin: 0 auto;
    display: block;
}

body.verify {
    #order__autorenewal__container,
    .order__finish__terms__and__conds__agreement {
        text-align: center;
    }
    
    .btn-submit {
        float: none;
    }
    
    .btn-success {
        margin-bottom: @main-padding;
    }

    @media screen and (max-width: 599px) {
        .products-totals .products-total-left, .products-totals .products-total-right {
            display: table;
            width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        #order__ccprocess__form .helpClass, .form-table-verify .helpClass {
            padding-left: 0;
        }
        .form-table-verify > tbody > tr > td {
            display: table;
            width: 100%;
        }
        .form-table-verify > tbody > tr {
            .clearfix();
            display: block;
            padding-bottom: @table-cell-padding;
        }
    }
}


/* FINISH PAGE */
body.finish {
    .btn-submit {
        float: none;
    }
    
    @media screen and (max-width: 700px) {
        #popup {
            width: 100% !important;
            height: 100% !important;
            left: 0 !important;
            top: 0 !important;
            iframe {
                height: 100% !important;
            }
        }
    }
}

/* RETRY PAGE */
body.ccprocess {
    #header, #subheader, #main {
        display: none;
    }
    input[type="text"] {
        width: 100%;
    }
    input[type="text"], .select-box {
        margin-bottom: @table-cell-padding;
    }
    .helpClass {
        padding-left: @padding-small-horizontal;
        display: inline-block;
    }
    @media screen and (max-width: 600px) {
        .helpClass {
            padding-left: 0;
        }
        #frmFinish > table > tbody > tr > td {
            display: table;
            width: 100%;
            text-align: left;
        }
        #frmFinish > table > tbody > tr:last-of-type > td {
            text-align: center;
        }
        #AuthorizeButton--btn {
            float: none;
        }
        #order__autorenewal__container, .order__finish__terms__and__conds__agreement {
            text-align: center !important;
        }
    }
}

#order__ccprocess__form > .order__box {
    visibility: hidden;
}

#order__ccprocess__form.review > .order__box {
    visibility: visible;
}

body.ccprocess.rtl {
    @media screen and (max-width: 767px) {
        #frmFinish > table > tbody > tr > td {
            text-align: right;
        }
    }
}

/* DDUK finish page update information */
body.finish.page-order-complete #dialogDirectDebitUk #UpdateInformation {
    .btn;
    .btn-default;
    .btn-success;
    .btn-submit;
    .btn-lg;
}

/* CUSTOMER SUPPORT */
body.page-support {
    #subheader, #footer {
        display: none;
    }
    table {
        width: 100%;
    }
    table.support_menu {
        width: 540px;
    }
    .support_container_top td {
        vertical-align: top;
    }
    .support_container_top,
    .support_bottom_boxes {
        border: 1px solid @main-border-color;
    }
    .support_box_login {
        border-top: 0px solid transparent;
        border-right: 0px solid transparent;
        width: auto;
        input {
            width: 100%;
        }
    }
    input {
        margin-bottom: @table-cell-padding;
    }
    .support_box_content input {
        width: 100%;
    }
    .support_language {
        margin-bottom: 15px;
    }
    @media screen and (max-width: 599px) {
        table.support_menu {
            width: 100%;
        }
        table > tbody > tr > td {
            display: table;
            width: 100% !important;
            text-align: left;
        }
        #faq_search_form #faq_search_input {
            width: 100%;
        }
        .support_box_login {
            border-left: 0px solid transparent;
        }
        .support_icon {
            display: none;
        }
    }
}

body.page-support.rtl {
    @media screen and (max-width: 599px) {
        table > tbody > tr > td {
            text-align: right;
        }
    }
}

/* EXPRESS CHECKOUT */
// body.page-express-payments-checkout #order__checkout__billing__payoptions__table {
body.page-express-payments-checkout #payment_radios_wrap_tr {
    display: none;
}
body.page-express-payments-checkout #pay_option_dduk_fields > td {
    padding-top: 10px;
}
body.page-express-payments-checkout .payment__content {
    max-width: 750px;
    margin: 0 auto;
}
.payment-options-wrapper-express {
    .payment-options-combo .payment-options-select, .payment-options-combo .payment-option {
        display: list-item;
    }
    
    .payment-options-combo.layout-5 > .payment-option label {
        display: inline-block;
        padding: 5px 10px;
        text-align: left;
    }

    .payment-options-combo.layout-5 > .payment-option .payment-option-title {
        display: inline-block;
        margin-bottom: 0;
    }

    .payment-options-combo.layout-5 > .payment-options-select {
        float: none;
    }

    .payment-options-select {
        padding: 0;
    }

    .payment-options-select-selected-option {
        width: 100%;
    }

    .payment-options-combo.layout-5 > .payment-option, .payment-options-combo.layout-5 > .payment-options-select,
    .payment-options-combo.layout-5 > .payment-option.selected, .payment-options-combo.layout-5 > .payment-options-select.selected {
        background-image: none;
    }
    
    .payment-options-combo.layout-5 > .payment-options-select {
        padding: 0;
    }
}
body.payment-options-wrapper-express.rtl {
    .payment-options-combo.layout-5 > .payment-option label {
        text-align: right;
    }
}

/* RIGHT TO LEFT TEXT
   -------------------------------- */

body.rtl {
    #tiCVV_error {
        position: absolute;
        left: auto;
        right: 175px;
        padding: @padding-base-vertical 0;
    }
    table.form-table-billing .text, table.form-table-billing-payment .text {
        float: right;
    }
    div.products-total-row {
        text-align: left;
    }
    .info-link {
        float: right;
        margin-left: 0;
        margin-right: 10px;
    }
    span.order__help_billing {
        text-align: right;
    }
    .valid, .order__text__field__mandatory.order__text__field__error, .order__text__field.order__text__field__error {
        background-position: 2% center;
    }
}

/* MEDIA QUERIES */
@media screen and (max-width: 844px) {
    #order__header__languages, #order__header__currencies {
        float: none;
    }
    #order__header__currencies {
        text-align: left;
        padding-top: @table-cell-padding;
    }
    body.rtl {
        #order__header__languages,
        #order__header__currencies {
            text-align: right;
        }
    }
}

@media screen and (max-width: 550px) {
    #order__header__languages, #order__header__currencies {
        .select-box {
            width: 100% !important;
        }
    }
    /*https://redmine.avangate.com/issues/15744*/
    #tiCVV_error {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .payment-options-wrapper-express .payment-options-combo.layout-5 > .payment-option {
        padding: 5px 0;
    }

    .payment-options-wrapper-express .payment-option label,
    .payment-options-wrapper-express .payment-options-combo.layout-5 > .payment-option label {
        display: inline;
    }
}

@media screen and (max-width: 400px) {
    div.products-content__billing,
    div.products-content__subtotal,
    div.products-content__totalvat {
        font-size: 90%;
    }

    div.products-content__total {
        font-size: 92.5%;
    }
}

/*McAffee logo*/
.mfes-trustmark {
    background: url('@{resources-domain}/ordermobile/imgs/mcafee_big.png') no-repeat center center transparent;
    background-size: contain;
}

/* APS-8027 : START  */
#place_order_terms_and_conditions  {
    .tooltip {
        position: relative;
        &:hover {
            .tooltip_text {
                display: block;
                //gave 300px alignment because from backend the tooltip has a 280 width;
                top: 100%;
                @media screen and (min-width: @screen-xs-min) {
                    right: 300px;
                }
                .tooltip_text_wrap {
                    display: block!important;
                    position: absolute;
                    .tooltip_title {
                        display: none;
                    }
                    .tooltip_content {
                        background: #fff!important;
                        color: #000!important;
                        position: relative;
                        z-index: 1;
                        border: @field-helper-popup-border;
                        box-shadow: @field-helper-popup-box-shadow;
                    }
                }
            }
        }
    }
    #hide_auto_renewal_show_terms_and_conditions_and_renewal {
        .label {
            padding: 0;
        }
        & > div {
            display: inline;
        }
    }
}
/* APS-8027 : END  */

#fcode_text, #bill_phone_info{
    td:first-of-type{
        display: table-cell!important;
    }
}

/*APS-8111:start*/
.page-support{
    .faq__rate__option{
        text-align: center!important;
    }
}
/*APS-8111:end*/

