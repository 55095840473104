
/*Leave page pop-up variables*/
@leave-bg-image: url('http://localhost/resources/gen-img-1.jpg');
@leave-base-grey: #979797;
@leave-main-border: 5px solid @leave-base-grey;
@leave-base-font-size: 70px;
/*Leave page styles*/
.leave-page-modal{
  padding: 0!important;
  .ui-dialog-titlebar{
    position: absolute;
    right: 0;
    .ui-dialog-titlebar-close,
    .ui-state-hover,
    .ui-state-focus{
      border-radius: 50%;
      padding: 5px;
      background: #515151;
    }
    .ui-state-hover{
      background: lighten(#515151, 15%);
    }
  }
  #leave-page-pop-up{
    background-image: @leave-bg-image;
    padding: 45px;
    text-align: center;
    @media screen and (max-width: 959px){
      top: 0!important;
    }
    .headline{
      border-left: @leave-main-border;
      border-right: @leave-main-border;
      &-primary{
        color: transparent;
        font-size: @leave-base-font-size;
        font-weight: 700;
        line-height: 70px;
        text-transform: uppercase;
        -webkit-text-stroke-color: #fff;
        -webkit-text-stroke-width: 1px;
        .ie & {
          color: @leave-base-grey;
        }
      }
      &-secondary {
        font-size: calc(@leave-base-font-size / 2.8);
        line-height: calc(@leave-base-font-size / 2.3);
        text-transform: uppercase;
        font-weight: 700;
        color: @leave-base-grey;
        padding-top: 10px;
        //background-image: -webkit-radial-gradient(circle, #dedede, @leave-base-grey);
        //-webkit-background-clip: text!important;
        //-webkit-text-fill-color: transparent;
      }
    }
    .offer-copy{
      color: #fff;
      font-size: calc(@leave-base-font-size / 3.8);
      padding: 20px 0 25px 0;
      b{
        text-transform: uppercase;
      }
    }
    .leave-page-cta{
      background-color: #FFA640;
      border: none;
      border-radius: 3px;
      color: #000;
      font-weight: bold;
      padding: 15px 60px;
      text-transform: uppercase;
    }
  }
}