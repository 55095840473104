#order__container {
    visibility: hidden;
}

.page-preloader {
    width: 100%;
    height: 100%;
    background: url('@{page-loader-url}') no-repeat center center @page-loader-bg;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}