.table,
#card-others-excluded ul {
    display: table;
    width: 100%;
}
.table-row {
    display: table-row;
    width: 100%;
}
.center {
    text-align: center !important;
}
.table > *,
.table-row > * {
    display: table-cell;
    vertical-align: top;
}

table.form-table-userinfo td,
.order__checkout__addfields__content td,
.back-to-shopping-wrapper td {
    padding-bottom: @table-cell-padding;
}

.order__checkout__summary > tbody > tr > td,
.order__checkout__summary > tr > td {
    padding-bottom: @table-cell-padding;
}

/* CUSTOM STYLES
   -------------------------------- */
body.layout-1 table.form-table-userinfo td.order__checkout__form__label {
    width: @form-label-width;
}