/* ==========================================================================
   DEFAULT LAYOUT
   ========================================================================== */

.cart-steps {
    position: relative;
    padding: @cart-steps-padding;
    margin: @cart-steps-margin;
}

#steps-wrap {
    width: 100%;
    font-size: @cart-steps-font-size;
    table-layout: fixed;
    position: relative;
    z-index: 2;
    #steps {
        .step {
            text-align: center;
        }
        .step-box-out {
            display: none;
        }
        .step-nr-wrapper,
        .step-name {
            display: inline-block;
            background: @cart-steps-bg;
            padding-right: 10px;
            line-height: 30px;
        }
        .step-name {
            vertical-align: middle;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 75%;
            @media only screen and (max-width : 991px) {
                max-width: 65%;
            }
        }
        .step.colored .step-name {
            color: @cart-steps-active-color;
        }
        .step-nr-wrapper {
            padding-left: 10px;
        }
        .step-nr {
            line-height: 30px;
            width: 30px;
            text-align: center;
            border-radius: 50%;
            background-clip: padding-box;
            box-sizing: content-box;
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            border: 1px solid @cart-steps-active-color;
            color: @cart-steps-active-color;
        }
        .step-nr-text {
            font-weight: bold;
        }
        .step-icon {
            display: none;
        }
        .step.checked .step-nr,
        .step.current .step-nr {
            background: @cart-steps-active-color;
            color: #FFF;
        }
    }
}

.rtl #steps-wrap.default #steps .step-name {
    padding-right: 0;
    padding-left: 10px;
}

#steps-progress-bar-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    -webkit-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    -ms-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    z-index: 1;
    width: 100%;
    table-layout: fixed;
    #steps-progress-bar {
        width: 100%;
    }
    #steps-progress-bar-content {
        .step-bar {
            font-size: 1px;
        }
        .step-bar-left-content,
        .step-bar-right-content {
            width: 50%;
            height: 0;
            border-top-width: 2px;
            border-top-style: solid;
            border-top-color: @cart-steps-inactive-color;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: @cart-steps-inactive-color;
        }
        .step-bar-left-content {
            float: left;
        }
        .step-bar-right-content {
            float: right;
        }
        .step-bar.checked .step-bar-left-content,
        .step-bar.checked .step-bar-right-content,
        .step-bar.current .step-bar-left-content {
            border-top-color: @cart-steps-active-color;
            border-bottom-color: @cart-steps-active-color;
        }
        .step-bar.first.checked .step-bar-left-content,
        .step-bar.first.current .step-bar-left-content {
            border-top-color: transparent;
            border-bottom-color: transparent;
        }
        .step-bar.last .step-bar-right-content {
            border-top-color: transparent;
            border-bottom-color: transparent;
        }
    }
}

#steps-progress-bar-wrap.dotted .step-bar .step-bar-left-content,
#steps-progress-bar-wrap.dotted .step-bar .step-bar-right-content {
    border-top-style: dotted !important;
    border-bottom-style: dotted !important;
}

#steps-progress-bar-wrap.dashed .step-bar .step-bar-left-content,
#steps-progress-bar-wrap.dashed .step-bar .step-bar-right-content {
    border-top-style: dashed !important;
    border-bottom-style: dashed !important;
}

.rtl #steps-progress-bar-wrap #steps-progress-bar-content {
    .step-bar-left-content {
        float: right;
    }
    .step-bar-right-content {
        float: left;
    }
}

#steps-progress-bar-wrap.default {
    transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    -webkit-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
    -ms-transform: translateY(-50%) translateZ(0) scale(1.0, 1.0);
}


/* ==========================================================================
   BROWSER COMPATIBILITY
   ========================================================================== */

.ie-7 #steps-progress-bar-wrap.default,
.ie-7 #steps-progress-bar-wrap.preset- {
    top: 35%;
}

.ie-7 #steps-progress-bar-wrap.preset-4 {
    top: 30%;
}

.ie-7 #steps-progress-bar-wrap #steps-progress-bar-content .step-bar-right-content {
    margin-top: -4px;
}

.ie-7 #steps-progress-bar-wrap.preset-1 #steps-progress-bar-content .step-bar-right-content,
.ie-7 #steps-progress-bar-wrap.preset-2 #steps-progress-bar-content .step-bar-right-content,
.ie-7 #steps-progress-bar-wrap.preset-3 #steps-progress-bar-content .step-bar-right-content,
.ie-7 #steps-progress-bar-wrap.preset-5 #steps-progress-bar-content .step-bar-right-content {
    margin-top: -2px;
}

.ie-7 #steps-wrap #steps .step-nr-wrapper {
    background: transparent;
}

.ie-7 #steps-wrap #steps .step-nr {
    background: @cart-steps-bg;
}

.ie-7 #steps-wrap #steps .step-name {
    padding-top: 5px;
}

.ie-7 #steps-wrap.preset-5 #steps .step-box-out {
    top: 15px;
}

.ie-8 #steps-wrap.preset-5 #steps .step-box-out,
.ie-9 #steps-wrap.preset-5 #steps .step-box-out {
    top: 16px;
}

.ie-7 #steps-wrap.preset-5 #steps .step-name,
.ie-8 #steps-wrap.preset-5 #steps .step-name,
.ie-9 #steps-wrap.preset-5 #steps .step-name {
    padding-top: 26px;
}


/* ==========================================================================
   MEDIA QUERIES
   ========================================================================== */

@media screen and (max-width: 844px) {
    #steps-wrap #steps .step .step-name {
        display: none;
    }
    #steps-wrap #steps .step.current .step-name {
        display: inline-block;
    }
    #steps-wrap #steps .step.current,
    #steps-progress-bar-wrap #steps-progress-bar .step-bar.current {
        width: auto;
    }
    #steps-wrap #steps .step,
    #steps-wrap #steps .step.first,
    #steps-wrap #steps .step.last,
    #steps-progress-bar-wrap #steps-progress-bar .step-bar,
    #steps-progress-bar-wrap #steps-progress-bar .step-bar.last,
    #steps-progress-bar-wrap #steps-progress-bar .step-bar.first {
        width: 100px;
    }
}

@media screen and (max-width: 479px) {
    #steps-wrap #steps .step,
    #steps-progress-bar-wrap #steps-progress-bar .step-bar {
        width: 65px;
    }
    
    #steps-wrap #steps .step {
        display: none;
        &.first,
        &.current,
        &.last {
            display: table-cell;
        }
    }
}
