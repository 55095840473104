body.custom-placeholders table.form-table-userinfo  {
    .text {
        width: 100%;
    }
    
    .select-box {
        width: 100% !important;
    }
    
    #cbExpMounth-select-box {
        width: 145px !important;
    }
    #cbExpYear-select-box {
        width: 83px !important;
    }
    
    #order_person_company .order__checkout__form__label {
        display: none;
    }

    #sameaddr td {
        // display: block !important;
        float: left;
        width: 100%;
    }
    #sameaddr .order__checkout__form__label {
        padding-right: 0;
        padding-bottom: 0;
    }

    #fcode_text, #bill_phone_info{
        td:first-of-type{
            display: none!important;
        }
    }
    
    .placeholderSpan label {
        color: lighten(@input-color, 30%) !important;
    }

    #fiscalcode {
        margin-bottom: 15px;
    }
}
