/* Affiliates  Page */

#wrap-thankyou,
#wrap-form {
  background: @box-content-bg;
  border: @box-border;
  border-radius: @box-border-radius;
  background-clip: padding-box;
  box-shadow: none;
  clear: both;
  display: block;
  margin-bottom: @box-margin-bottom;
  margin-top: @box-margin-bottom;
  padding: 20px 30px;
  position: relative;
  line-height: @line-height-small;
  font-size: @font-size-base;
}

#wrap-form h3,
#wrap-form h2 {
  font-size: @font-size-base;
  font-family: @font-family-base;
  color: @box-header-color;
  font-weight: @box-header-font-weight;
  padding: 0;
  margin-top: 0;
}

#wrap-form h2 {
  margin-bottom: 20px;
}

#wrap_form_lang {
  position: absolute;
  top: 20px;
  right: 20px;
}

.heading-parent {
  background-color: @box-header-bg;
  border: @box-border;
  border-radius: @box-border-radius;
  clear: both;
  display: block;
  height: auto !important;
  margin-bottom: 15px;
  padding: 10px !important;
  width: 100%;
}

.page-affiliates #subheader,
.page-affiliates #footer {
  display: none !important;
}

#privacy-policy-text,
#privacy-policy-text a,
.frm-label,
select,
.promotions-span,
#wrap-form .label-text,
#wrap-form .label {
  font-size: @font-size-base;
}

.signup-form input.text,
.signup-form input.password {
  width: 250px;
}

.td_padding_btm {
  position: relative;
}

.page-affiliates .valid {
  background: none !important;
}

.warningicon {
  position: relative;
  top: @input-height-base / 3;
}

.page-affiliates .select-box select.force-reset-select {
  top: 0 !important;
}

#CountryCode-select-box {
  float: left;
}

.page-affiliates .table-form {
  margin: 10px 0;
}

.page-affiliates .signup-form .top-buffer10.show {
  margin: 10px 0;
}

#tooltip > h3 {
  width: 300px !important;
  padding: 10px;
  word-wrap: break-word !important;
}

.rtl #wrap_form_lang {
  left: 20px !important;
  right: auto !important;
  padding-right: 20px;
}

@media screen and (max-width: 612px) {
  #wrap_form_lang {
    position: relative !important;
    top: -10px !important;
  }
}

.page-affiliates .col-sm-7 {
  width: 100%;
}

.table-form-rtl .checkbox {
  margin-right: -20px !important;
}

table.table-form-rtl {
  float: none !important;
}

#wrap_Promotion_Methods {
  padding-top: 30px;
}

.page-affiliates {
  .wrapper {
    display: none;
  }

  .table-form td {
    vertical-align: middle;

    &.td_padding_btm {
      padding-bottom: @table-cell-padding;

      select,
      textarea,
      input[type="text"],
      input[type="password"],
      input.order__text__field,
      textarea.order__textarea__field,
      select.order__select__field {
        width: 100%;
      }
    }
  }
}

#signup-form-wrap {
  input.text,
  .select-box {
    min-width: 50px !important;
    max-width: 220px;
    overflow-x: auto;
  }

  .warningicon {
    margin-left: -20px;
    padding-left: 20px;
  }

  .label {
    margin-right: 10px;
  }
}
