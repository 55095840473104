.container {
  max-width: 990px;
}

body{
  .submit-large.submit-super.submit-button{
    border-radius: @border-radius-base !important;
    padding: @btn-padding-large-vertical @btn-padding-large-horizontal !important;
    background-image: none!important;
    font-size: @font-size-large !important;
  }
}

.billing__content,
.payment__content {
  max-width: 550px;
  margin: 0 auto;
}

table.order__checkout__billing,
table.form-table-userinfo {
  .text {
    width: 100%;
  }
  .select-box {
    width: 100% !important;
  }
  #cbExpMounth-select-box {
    width: 145px !important;
  }
  #cbExpYear-select-box {
    width: 83px !important;
  }
}

#card__data__expdates #cbExpYear-select-box:not(.order__select__field__error) {
  width: auto !important;
  .selected-option {
    width: auto;
    padding-right: 30px;
  }
}

table.form-table-userinfo td.order__checkout__form__label,
table.form-table-userinfo td.order__checkout__form__label__error:not(.order__checkout__form__input):not(.order__checkout__form__input__error) {
  width: @form-label-width;
}

body.payment table.form-table-userinfo td.order__checkout__form__label__error:not(.order__checkout__form__input):not(.order__checkout__form__input__error){
  width: 160px!important;
}

// FORM
.order__checkout__form__input {
  .clearfix();
}

// Media queries
@media screen and ( max-width: @screen-xs-max ) {
  // Display form on small screens
  .order__checkout__form__label, .order__checkout__form__input, .form-table-userinfo .note, .form-table-userinfo td:empty {
    //    display: table-cell;
  }

  .order__checkout__billing__content > tbody > tr > td:first-child:not(#data__bank_issuer__wrap) {
    //display: none;
  }

  .order__checkout__billing__content > tbody > tr > td:first-child.order__checkout__form__label,
  .order__checkout__billing__content > tbody > tr > td:first-child.order__checkout__form__label__error,
  .order__checkout__billing__content #payment__methods__icons td:first-child,
  #order__checkout__billing__info__content__tbody td:first-child,
  #credit__cart__fields__container td:first-child {
    display: none;
  }

  table.form-table-userinfo td.order__checkout__form__label,
  table.order__checkout__billing td.order__checkout__form__label {
    //   padding-bottom: 0;
  }

  //    .form-table-userinfo td:empty,
  .form-table-userinfo .note {
    //    margin-top: -@table-cell-padding;
  }

  .order__checkout__addfields__content td {
    padding-bottom: @table-cell-padding;
  }

  .products__title.row,
  .order__listing__item__remove,
    //.order__backupcd__option,
    //.order__dis__option,
  .back-to-shopping-wrapper,
  .payment .order__box__title,
  .assistance__content .assistance-content__left,
  .top-info,
  .order__listing__row .order__product__options {
    display: none;
  }

  /* Cart Steps for mobile devices : START */
  #steps-wrap #steps {
    .step.step-2 {
      display: table-cell;
    }
    .step.current .step-name {
      display: none;
    }
  }

  /* Cart Steps for mobile devices : END */
  .payment-options {
    width: 100% !important;
    & > .payment-option,
    & > .payment-options-select {
      text-align: center !important;
      & .payment-options-select-list {
        text-align: left;
      }
    }
    & > .payment-option.selected {
      border-left: 5px solid green;
    }
  }

  .payment-options-wrapper {
    background: transparent;
    box-shadow: none;
    border: none;
    .payment-options.payment-options-combo {
      & .payment-option {
        display: list-item !important;
        border: 1px solid @payopt-border-color !important;
        margin-bottom: 10px !important;
        box-sizing: border-box;
        background: #FFF;
        box-shadow: @payopt-box-shadow;
        & > label {
          border-left: 5px solid transparent;
          text-align: left;
          padding: 10px !important;
          .payment-option-image {
            float: right;
          }
          .payment-option-title {
            line-height: 36px;
          }
        }
        &:hover,
        &.selected {
          & > label {
            border-left: 5px solid @payopt-active-border-color;
          }
        }
        /* APS-6295 : START */
        &#payment-option-cc {
          .payment-option-image {
            &.pay_opt_1 {
                margin-left: 3px;
            }
            &:not(.pay_opt_1):not(.pay_opt_4) {
                display: none;
            }
          }
        }
        /* APS-6295 : END */
      }
      .payment-options-select {
        width: 100%;
        padding: 0px;
        &:hover {
          border: none !important;
        }
        .payment-options-select-selected-option {
          border-color: @payopt-border-color;
          padding: 10px;
          box-shadow: @payopt-box-shadow;
          width: 100%;
          height: 100%;
          //border-left: 5px solid transparent;
          .payment-options-select-selected-option-value {
            height: 36px !important;
            line-height: 36px !important;
            font-weight: bold;
            padding: 0px;
            width: 100%;
            text-align: center;
            color: @brand-primary !important;
            img {
              display: initial;
              float: right;
            }
          }
          &.focus {
            height: 0px !important;
            display: none !important;
            padding: 0px;
            transition: none !important;
            -moz-transition: none !important;
            -webkit-transition: none !important;
          }
          .select-box-arrow-wrapper {
            display: none;
          }
        }
        .payment-options-select-list {
          display: none;
          position: relative;
          padding: 0px;
          border: none;
          transition: none !important;
          -webkit-transition: none !important;
          -moz-transition: none !important;
          background-color: transparent !important;
          & > li.payment-option {
            padding: 0px;
            margin-bottom: 10px !important;
          }
          &.active {
            display: block !important;
          }
        }
        &.selected {
          border: none;
          .payment-options-select-selected-option {
            border-left: 5px solid @payopt-active-border-color;
            .payment-options-select-selected-option-value {
              text-align: left;
              color: @text-color !important;
            }
          }
        }
      }
    }
  }

  .payment-options-select-clicked {
    display: none;
  }

  .payment-options-select-list-always-open {
    display: block !important;
    height: auto !important;
    .payment-option {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  table.order__checkout__billing #cbExpMounth-select-box,
  table.order__checkout__billing #cbExpYear-select-box {
    width: 100% !important;
  }

  #cbExpMounth-select-box {
    //  margin-bottom: @table-cell-padding;
  }

  table.order__checkout__addfields__content {
    .text {
      width: 100%;
    }
    .select-box {
      width: 100% !important;
    }
  }

  .products-total-right.products-content__totals {
    padding-top: 20px !important;
  }

  /* .payment-options-label {
      width: 100%!important;
      border-bottom: 1px solid @main-border-color;
  } */
  .order__listing__row {
    .clearfix();
    display: block;
    .order__listing__item__name {
      padding: 0px 5px !important;
    }
    .order__listing__item__image {
      display: inline-block;
      width: 36% !important;
    }
    .order__listing__item__name {
      display: inline-block;
      width: 60% !important;
    }
    .order__listing__item__qty,
    .order__listing__item__total__price {
      float: left;
      display: inline-block;
      //text-align: right!important;
      padding-top: 20px;
      width: 50%;
    }
    .order__listing__item__qty {
      //  width: 40%;
    }
    .order__listing__item__total__price {
      //   width: 47%;
    }
    // Customisation only for the discount row - to look good on mobile
    /*  &.order__product__discount {
       background-color: lighten(@brand-success, 55%);
       .order__listing__item__name,
       .order__listing__item__total__price {
           float: left;
           width: 50%!important;
           border-bottom: none;
           padding-top: 20px!important;
       }
   } */
    &.discounted-product-row {
      .order__listing__item__total__price {
        .order__billing__price {
          display: block;
        }
      }
    }
  }

  #order__totals {
    .products-total-row.products-totalvat,
    .products-total-row.products-total.order__total {
      //font-weight: bold;
      span.prod-total-text {
        float: left;
      }
    }
  }

  .order__checkout__button__container {
    .btn-success.btn-submit {
      width: 100%;
    }
  }

  .order__listing__item__image {
    padding: 0px !important;
    img {
      width: 100%;
      height: auto;
    }
  }

  &.product {
    /* .back-to-shopping-new-wrapper {
        td {
            display: block;
            width: 100%;
        }
        .order__product__buttons {
            text-align: center;
            button {
                width: 100%;
            }
        }
    } */
    /*  .order__product__options {
       & > li {
           width: 49%;
       }
   } */
    .order__product__info {
      table {
        width: 100%;
        & > tbody > tr > td:last-child {
          text-align: right;
        }
      }
      .order__product__qty {
        .clearfix();
        & > p {
          float: left;
        }
        & > input,
        & .quantity-field-wrapper {
          float: right;
        }
      }
      .order__product__info__value {
        padding-right: 0px;
      }
      .order__product__volume__discount {
        text-align: right;
      }
    }
  }

  &.verify {
    .order__listing__row {
      .order__listing__item__name {
        width: auto !important;
        float: left;
        padding-top: 10px !important;
      }
      .order__listing__item__total__price {
        width: 100%;
        padding-top: 10px;
      }
      .order__listing__item__total__price {
        float: right;
      }
      .order__product__discount {
        .order__listing__item__total__price {
          float: right;
        }
        td {
          padding-top: 15px !important;
        }
        td:first-child {
          float: left;
        }
      }
    }
    .delivery__content {
      td:empty {
        display: none;
      }
      #order__billing__address,
      #order__delivery__address {
        display: block;
        width: 100%;
      }
      #order__billing__address {
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and ( min-width: @screen-sm-min ) {
  /* &.custom-placeholders table.form-table-userinfo #sameaddr {
      .order__checkout__form__label,
      .order__checkout__form__label__error {
          max-width: 160px;
      }
      td {
          display: table-cell!important;
          float: none!important;
          padding-bottom: 12px;
          vertical-align: top;
          width: auto!important;
      }
  } */
  #order__header {
    display: none;
  }

  &.custom-placeholders table.form-table-userinfo #sameaddr {
    .order__checkout__form__label,
    .order__checkout__form__label__error {
      max-width: 160px;
    }
    td {
      display: table-cell !important;
      float: none !important;
      padding-bottom: 12px;
      vertical-align: top;
      width: auto !important;
    }
  }

  .order__listing__item__total__price {
    width: 20%;
    br {
      display: none;
    }
  }

  #order_person_company {
    td:first-child {
      display: table-cell !important;
    }
  }

  /*======================================================*/
  /* Billing Information : START */
  #bill_phone_info,
  #sameaddr {
    .note {
      display: block;
      max-width: 85%;
    }
  }

  #order__checkout__billing__info__content__tbody > tr > td:first-child {
    //   max-width: 120px;
  }

  // table.form-table-userinfo td.order__checkout__form__label,
  // table.form-table-userinfo td.order__checkout__form__label__error:not(.order__checkout__form__input):not(.order__checkout__form__input__error) {
  //   width: 160px !important;
  // }

  /* Billing Information : END */
  /*======================================================*/
  /* Payment Options Widget : START */
  .payment-options-card-icons {
    right: 15%;
  }

  .payment-options-wrapper {
    margin-bottom: 20px;
    /* .payment-options-label, */
    .payment-options {
      display: inline-block;
    }
    .payment-options {
      vertical-align: middle;
      margin-bottom: 0px;
    }
    /*  .payment-options-label {
       width: 25%;
       padding: @box-header-padding;
       color: @box-header-color;
       font-weight: @box-header-font-weight;
       font-size: 18px;
   } */
    .payment-options.payment-options-combo {
      width: 100%;
      text-align: right;
      & > .payment-option {
        text-align: center;
        //width: 18%;
        background-color: @payopt-bg-color;
        border: 1px solid @payopt-border-color;
        box-shadow: @orderbox-box-shadow;
        &:not(:last-child) {
          margin-right: 10px;
        }
        & > label {
          padding: 10px 15px !important;
          background-color: transparent;
          .payment-option-title {
            display: none;
          }
        }
      }
      .payment-option {
        border-radius: 0px;
      }
      & > .selected {
        box-shadow: none !important;
        background: @payopt-active-bg-color !important;
        border-radius: 0px;
        border-color: @payopt-active-border-color !important;
        border-width: 3px;
        border-style: solid;
        &:after {
          content: " ";
          display: block;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid @payopt-active-border-color;
          position: absolute;
          top: 110%;
          left: 50%;
          margin-left: -8px;
        }
      }

      .payment-options-select {
        //width: 40%;
        height: 56px;
        margin-top: 2px;
        box-shadow: @orderbox-box-shadow;
        max-width: 255px;
        &.selected {
          .payment-options-select-selected-option {
            padding: 0px 10px;
            .payment-options-select-selected-option-value {
              line-height: 48px !important;
              white-space: normal;
            }
            .select-box-arrow-wrapper {
              top: 3px;
            }
          }
        }
        .payment-options-select-selected-option {
          border: 1px solid @payopt-border-color;
          width: 100%;
          height: 100%;
          padding: 5px 10px;
          min-width: 200px;
          .select-box-arrow-wrapper {
            height: 44px;
          }
        }
        .payment-options-select-list {
          border: 1px solid @payopt-border-color;
          text-align: left;
          padding: 0px;
          & .payment-option {
            border-left: 5px solid transparent;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            & label {
              padding: 10px;
              border-top: 1px solid transparent;
              border-bottom: 1px solid transparent;
            }
            &:hover {
              border-left: 5px solid @payopt-active-border-color;
              background-color: @payopt-active-bg-color;
              & label {
                border-color: @payopt-border-color;
              }
            }
          }
        }
      }
    }
  }

  /* Payment Options Widget : END */
  .products-total-row.products-content__totalvat,
  .products-total-row.products-content__total,
  .products-total-row.products-total-trial {
    span.prod-total-text {
      float: none !important;
      font-weight: normal;
    }
  }

  .products-total-row.products-content__total {
    .order__display__total,
    .order__billing__total {
      color: @brand-success;
      font-size: @order-total-font-size;
    }
  }

  .products-total-row.products-total-trial {
    .order__display__total,
    .order__billing__total {
      font-size: @order-total-font-size;
      font-weight: normal;
    }
  }

  .products-total-row {
    .order__billing__total {
      //   .clearfix();
    }
  }

  /* Input & select width */
  .order__checkout__billing__content {
    input[type="text"]:not([id="tiCVV"]),
    .select-box {
      max-width: 85% !important;
    }
  }

  /* Input & select width : END */
  .order__checkout__form__label,
  .order__checkout__form__label__error {
    //     display: initial!important;
  }

  .form-table-userinfo,
  .billing-currency-wrapper {
    :-moz-placeholder {
      color: transparent !important;
      display: none !important;
    }
    ::-moz-placeholder {
      color: transparent !important;
      display: none !important;
    }
    ::-webkit-input-placeholder {
      color: transparent !important;
      display: none !important;
    }
    :-ms-input-placeholder {
      color: transparent !important;
      //  display: none!important;
    }
  }

  #order__checkout__billing__info__content__tbody #order__email__marketing__signup td:first-child {
    display: initial !important;
  }

  /* Cross Sell for medium and large devices: END */
  &.checkout {
    .order__checkout__billing td:empty {
      display: table-cell !important;
    }
  }

  &.rtl {
    .payment-options-card-icons {
      left: 53px;
    }
  }

  &.product {
    .site-branding,
    .top-info {
      padding: 0px;
    }
    .order__product__image,
    .order-product-info-content-wrapper {
      display: table-cell !important;
    }
  }
}

@media screen and ( max-width: @screen-md-max) {
  #card__data__nr .field-helper .field-helper-popup {
    left: auto;
    right: 100%;
  }

  &.rtl #card__data__nr .field-helper .field-helper-popup {
    left: 100%;
    right: auto;
  }

  .order__listing__item__unit__price {
    display: none !important;
  }

}

@media ( min-width: @screen-md-min ) {
  .secure-checkout-seals {
    //   display: table-cell;
    //  float: unset;
    //   text-align: right;
  }

  .payment-options.payment-options-combo {
    & > .payment-option {
      // width: 12% !important;
    }
    .payment-options-select {
      width: 40%;
    }
  }
}

@media ( max-width: @screen-md-min ){
  .payment-options-select {
      width: 255px;
    }
}

/*======================================================*/

/* Layout 8 : START */

/*======================================================*/

.checkout.page-with-review {
  .btn-success.btn-submit {
    background-image: url('/images/merchant/67caec8041b2d689a5035d3bf441c34c/arrow-to-right.png?21');
    background-repeat: no-repeat;
    background-position: 80% 54%;
    padding: 12px 65px 12px 50px;
    transition: 0.2s;
    &:hover {
      background-position: 82% 54%;
    }
  }
  #order__page__upsell_product {
    .btn-success.btn-submit {
      //  background-image: none;
      //  padding-right: 65px;
    }
  }
}

.checkout.page-without-review,
.verify,
.product {
  .btn-success.btn-submit {
    background-image: url('/images/merchant/67caec8041b2d689a5035d3bf441c34c/lock.png');
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 20% center;
    padding: 12px 50px 12px 65px;
  }
}

.product .btn-success.btn-submit[id^="BuyNow"] {
  background-image: url('/images/merchant/67caec8041b2d689a5035d3bf441c34c/cart-icon.png');
  background-size: 25px;
}

#order__header {
  #display_currency-select-box {
    height: 37px;
    .selected-option {
      line-height: 37px;
    }
  }
  .select-box {
    .selected-option {
      color: @input-color-placeholder;
    }
  }
}

.payment-options.payment-options-combo {
  background: transparent;
  border-bottom: 0px solid transparent;
}

.payment-options-select .payment-options-select-selected-option-value {
  color: @input-color-placeholder !important;
}

:-moz-placeholder {
  color: @input-color-placeholder !important;
}

::-moz-placeholder {
  color: @input-color-placeholder !important;
}

::-webkit-input-placeholder {
  color: @input-color-placeholder !important;
}

:-ms-input-placeholder {
  color: @input-color-placeholder !important;
  //  display: none!important;
}

.select-box span.selected-option.placeholder-color {
  color: @input-color-placeholder !important;
}

.header-language-selector,
.header-display-currency {
  display: inline-block;
  //margin-left: 15px;
  .select-box {
    // max-width: 85px;
    width: auto !important;
    border: none;
    .selected-option {
      width: auto;
      padding-right: 30px;
    }
    .select-box-arrow-wrapper {
      border-left: none;
    }
  }
}

.header-display-currency {
  border-right: 1px solid @main-border-color;
  margin-right: 10px;
  padding-right: 10px;
}

/* ====================== */
/* Cart Steps  */
#steps-progress-bar-wrap #steps-progress-bar-content .step-bar.checked .step-bar-left-content,
#steps-progress-bar-wrap #steps-progress-bar-content .step-bar.checked .step-bar-right-content,
#steps-progress-bar-wrap #steps-progress-bar-content .step-bar.current .step-bar-left-content {
  border-bottom-color: @main-border-color;
  border-top-color: @main-border-color;
}

#steps-progress-bar-wrap #steps-progress-bar-content .step-bar.first.checked .step-bar-left-content,
#steps-progress-bar-wrap #steps-progress-bar-content .step-bar.first.current .step-bar-left-content {
  border-bottom-color: transparent;
  border-top-color: transparent;
}

#steps-wrap.preset-4 #steps {
  .step {
    color: #67768B;
    .step-nr {
      border: 1px solid @main-border-color;
      background-color: transparent;
      color: #67768B;
      .step-nr-text {
        font-weight: bold;
      }
    }
    &.checked,
    &.current {
      .step-nr {
        background-color: @cart-steps-active-color;
        border-color: @cart-steps-active-color;
        color: #FFFFFF;
      }
      .step-name {
        color: initial;
      }
    }
  }
}

/* Cart Steps : END */
/* ====================== */
#order__container {
  .order__listing__row.order__product__bundle__contents_title {
    .order__listing__item__name {
      font-weight: bold !important;
    }
    .order__listing__item__qty,
    .order__listing__item__total__price {
      display: none !important;
    }
  }
  .order__listing__row:not([class*="order__product__bundle"]) {
    // border-bottom: 1px solid @main-border-color;
  }
}

.order__listing__row {
  .order__listing__item__remove {
    padding-top: 17px;
  }
  & > td {
    vertical-align: top;
    padding-top: 12px;
  }
  &.discounted-product-row {
    // background-color: lighten(@brand-success, 55%);
    & > td {
      //  border-bottom: 20px solid #FFF;
      //background-color: lighten(@brand-success, 55%);
    }
    .undiscounted-price-wrapper {
      text-decoration: line-through;
      // color: @brand-danger;
      // font-weight: normal;
      // font-size: (@font-size-base - 1px);
    }
    .discounted-price-wrapper {
      color: @brand-danger;
    }
  }
  &.order__product__discount {
    display: none;
    td {
      border-bottom: none;
      vertical-align: middle !important;
      background-color: lighten(@brand-success, 55%);
    }
    .order__product__discount__price {
      color: lighten(@brand-success, 10%);
    }
  }
  &.order__product__bundle__contents_product,
  &.order__product__bundle__contents_title {
    td {
      padding-bottom: 0px;
      padding-top: 5px;
    }
    td:empty {
      display: none !important;
    }
    .order__listing__item__name {
      font-size: (@font-size-base - 1px) !important;
    }
  }
}

.order__box .order__box__title .order__box__aux2 {
  /* New Variable  - Heading Font size*/
  font-size: @heading-font-size;
}

/* ====================== */
/* Custom Buttons Icons */
.btn {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
}

/* Custom Buttons Icons : END */
/* ====================== */
#header {
  box-shadow: @orderbox-box-shadow;
  margin-bottom: 3px;
  padding: 12px 0;
  .hotline {
    margin-top: 0px;
    .icon-phone {
      color: #a6b8cc;
    }
    .hotline-language {
      .hotline-language-separator {
        margin: 0 5px;
        padding: 0px;
        border-right: 1px solid @main-border-color;
        color: transparent;
        display: inline-block;
        height: 35px;
        vertical-align: middle;
        @media screen and(max-width: 410px) {
          visibility: hidden;
        }
      }
      .hotline-language-content {
        .select-box-arrow-wrapper {
          border-left: 0px;
          right: 5%;
        }
      }
    }
  }
}

&:not(.product) #display_currency-select-box {
  height: 32px;
  .selected-option {
    position: relative;
    //top: 3px;
  }
  .selected-option,
  .select.currency__select {
    // line-height: 30px!important;
    // height: 30px!important;
  }
}

.products__title__text {
  line-height: 32px;
}

#order__container {
  background: transparent;
  border: none;
  padding: 0px !important;
  #bill_address2 {
    display: none;
  }
  /* .products-header-currency {
      text-align: right;
      #display_currency-select-box {
          max-width: 85px;
          .select-box-arrow-wrapper {
              height: 24px;
          }
      }
  } */
  .order__listing__row {
    color: @text-color;
    td {
      vertical-align: top;
    }
    .order__listing__item__remove {
      // padding-top: 5px;
      .remove-product {
        color: darken(@gray-lighter, 15%);
        transition: all 0.5s;
        &:hover {
          cursor: pointer;
          color: @brand-danger;
          transform: rotate(90deg);
        }
        /* change the trashcan icon to 'X' */
        .remove-product-button.img {
          background-image: url("@{resources-domain}/images/merchant/67caec8041b2d689a5035d3bf441c34c/x-remove-icon.png");
          height: 12px;
          width: 13px;
          &:hover{
            background-position: 0 -13px;
          }
        }
      }
    }
    .order__listing__item__image {
      width: 12%;
      text-align: center;
      img {
        max-width: 100px;
      }
    }
    .order__listing__item__name__dis,
    .order__listing__item__name__backupcd {
      td {
        color: @text-color;
        font-weight: bold;
      }
    }
    .order__listing__item__name__backupcd {
      .icon.icon-cd {
        display: none;
      }
    }
    .order__listing__item__name {
      //   width: 43%;
      color: @product-name-color;
      font-size: (@font-size-base + 1px);
      padding-right: 10px;
      padding-left: 10px;
      .order__product__volume__discount,
      .product-short-description,
      .order__product__options {
        font-size: (@font-size-base - 1px);
        //    color: @gray-light;
        font-weight: normal;
      }
      table {
        font-weight: normal;
      }
    }
    .order__listing__item__qty {
      text-align: center;
    }
    .order__listing__item__total__price {
      display: table-cell !important;
      text-align: right;
      color: @text-color;
      font-weight: bold;
    }
  }
  .currency__label {
    display: inline-block !important;
  }
  #order__totals {
    width: 100%;
    margin-top: 15px !important;
    border-style: solid;
    .products-total-right.products-content__totals {
      padding-top: 0px;
      .products-totalvat {
        color: @gray-light;
      }
    }
    .order-totals-wrapper {
      float: right;
    }
  }
}

.order__backupcd__option label,
#dis__text {
  margin-left: 10px;
}

.coupon {
  label[for="coupon-checkbox"] {
    margin: 0 5px;
  }
  .coupon__checkbox,
  .coupon__form {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
  }
  .coupon__checkbox {
    .custom-checkbox {
      vertical-align: top;
      margin-top: 5px;
    }
  }
}

.order__dis__option,
.order__backupcd__option {
  td {
    vertical-align: top;
    .custom-checkbox {
      margin-top: 5px;
    }
    a {
      white-space: nowrap;
    }
  }
}

/*=====================================*/
/* Quantity Field : START  */
.order__listing__item__qty {
  font-weight: bold;
  color: @text-color;
}

.quantity-field-wrapper {
  border-color: @quantity-field-border-color;
  .order__text__field.order__text__field__qty.text {
    color: @text-color;
    width: 45px;
    height: 34px;
  }
  .quantity-field__input,
  .quantity-field__modifier {
    padding-bottom: 0px;
    vertical-align: middle !important;
  }
  .quantity-field__modifier {
    .quantity-field-button {
      color: @text-color;
      outline: none;
      // font-size: (@font-size-base + 7px);
      line-height: 1;
      width: 100%;
      // &.minus,
      // &.plus {
      //   padding: 5px 14px 7px;
      // }
    }
    .quantity-field-button {
      background-color: @quantity-field-button-bg;
      &:hover {
        background-color: @quantity-field-button-bg-hover;
      }
    }
    .quantity-minus-input-plus .quantity-field-button.minus,
    .quantity-minus-plus-before .quantity-field-button {
      border-right: 1px solid @quantity-field-border-color;
    }
  }
}

/* Quantity Field : END  */
/*=====================================*/
/*======================================================*/
/* Billing Information : START */
#card__data__fullname {
  td {
    padding-bottom: 12px;
  }
}

#order__finalTotalPrice {
  background: none;
  padding: 0px;
  p {
    padding-top: 0px;
    .total_cost_wrap {
      color: @brand-success;
    }
  }
}

/* Billing Information : END */
/*======================================================*/
/* Payment Options Widget : START */
/* Payment Options Widget Express Flow : START*/
&.page-express-payments-checkout {
  #order__autorenewal__container {
    margin-bottom: 10px;
  }
  #order__finalTotalPrice {
    margin-bottom: 0px;
  }
  .in-box-button-container {
    margin-bottom: 0px;
    padding-bottom: 20px;
  }
}

.payment-options {
  background: transparent;
  border-bottom: none;
  img {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

/* Payment Options Widget Express Flow : End */
/* Payment Options Widget : END */
/*======================================================*/
#order__cart__contents.layout-1 .order__box {
  background: @main-bg;
  .order__box__title {
    background: @box-header-bg;
    .products__title.row {
      margin: 0px;
      padding: 4px 0px;
    }
  }
}

#order__checkout__payoptions__data {
  .order__box__footer {
    background: @main-bg;
  }
  .order__checkout__button__container {
    border-top: 1px solid @main-border-color;
    margin: 5px;
    padding: 10px;
  }
}

#footer {
  position: relative;
  a {
    text-decoration: underline;
    &:hover {
      color: @footer-headings-color;
    }
  }
  .assistance__title,
  .hotline__title {
    font-weight: normal;
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: @footer-background-opacity;
    background: @footer-background-image;
  }
}

/*======================================================*/
/* Checkout  */
&.checkout {
  .order__box__aux2.products__title {
    padding: 10px !important;
    margin: 0px !important;
  }
  .order__box .order__box__title .order__box__aux2 {
    padding: 10px;
  }
  &:not(.page-trial) {
    .order__listing__item__name {
      width: 43%;
    }
  }
}

/*======================================================*/
#secure-checkout {
  border: none;
  .secure-checkout-content {
    padding: 0px;
    .order__box__aux2 {
      padding: 0px;
      .secure-checkout-content-text,
      .secure-checkout-seals {
        display: block;
        width: 100%;
        text-align: center;
      }
      .secure-checkout-header,
      .avangate-logo {
        display: none;
      }
      .trust_logo_image {
        max-width: 100px;
      }
    }
  }
}

/*======================================================*/
/* Who Is Avangate : Start  */
.wia-popup {
  .ui-dialog-titlebar {
    background-color: @box-header-bg;
    color: @box-header-color;
    padding-bottom: 5px;
    padding-top: 0px;
    .ui-dialog-title {
      padding-top: 5px;
      font-family: @font-family-base;
      font-size: (@font-size-base + 3); //18px
      font-weight: normal;
    }
  }
  .ui-dialog-titlebar-close {
    background-color: @box-header-bg;
  }
  .who-is-avangate-logos-wrapper {
    margin-bottom: 15px;
    margin-top: 10px;
    .vendor-logo-wrapper {
      img {
        float: right;
      }
    }
  }
  .description {
    font-size: @font-size-base;
    font-family: @font-family-base;
    color: @text-color;
  }
  .icons {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 10px;
    img {
      max-width: 80px;
      margin-right: 10px;
    }
  }
}

/* Who Is Avangate : End  */
/*======================================================*/
.hotline-content__nr {
  color: @footer-headings-color;
  font-weight: normal;
}

.order__products__listing,
.billing,
.payment {
  & > .order__box {
    box-shadow: @orderbox-box-shadow;
  }
}

.billing > .order__box {
  //margin-bottom: 0px;
}

/*======================================================*/
/* Cross Sell  */
#x-sell {
  box-shadow: @orderbox-box-shadow;
  .add-cross-sell {
    padding: 10px 16px;
    border-color: @x-sell-button-border-color;
    color: @x-sell-button-color;
    font-size: @x-sell-btn-font-size;
    .btn-text {
      top: 0;
    }
    &:hover {
      color: #FFF;
      border-color: @x-sell-button-hover-border-color;
    }
  }
}

label,
.x-sell-product-title {
  color: @text-color;
}

/*======================================================*/
/* Select Box */
.select-box-arrow-wrapper {
  border-left: 1px solid @main-border-color;
  height: 26px;
  width: 30px;
  top: 5px;
  .select-box-arrow {
    color: @text-color !important;
  }
}

/*======================================================*/
/* Verify START */
#order__3dsecure__text,
.cc-logos {
  display: none;
}

&.verify {
  .order__listing__header,
  .products-total-row.products-subtotal.order__sub__total.products-content__subtotal {
    display: none;
  }
  #order__products .order__listing__row.order__product__bundle {
    .products-content__info {
      padding-top: 5px;
    }
    .order__listing__item__total__price {
      display: none !important;
    }
  }
  .order__listing__row {
    .order__listing__item__name {
      font-weight: bold;
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  #order__totals {
    border-top: 1px solid @main-border-color;
    padding-top: 20px;
  }
  .order__box:not(.assistance) {
    box-shadow: @orderbox-box-shadow;
    .order__box__content .order__box__aux2 {
      padding: 20px 15px;
    }
  }
  .table-exp-dates td {
    vertical-align: top;
  }
}

/* Verify END */
/*======================================================*/
/* Finish : START */
&.finish {
  .hotline-language-separator {
    display: none !important;
  }
  &.page-order-failed {
    .btn-success.btn-submit {
      padding: 12px 35px;
    }
  }
}

#frmFinish {
  #dTooltip {
    z-index: 1;
  }
}

/* APS-6295 : START */
.sprite-48.pay_opt_1 {
  background-position: 0px 112px;
  width: 98px;
}
  /* APS-6295 : END */

.payment-options-card-icons {
  & > img {
    margin-top: 2px;
    margin-right: 2px;
  }
  /* APS-6295 : START */
  .pay_opt_1 {
    width: 48px;
  }
  &.mastercard {
      .pay_opt_1 {
          background-position: -50px 112px;
      }
  }
  /* APS-6295 : END */
}

.sprite-48.pay_opt_4 {
  height: 33px;
  background-position: 0 1068px;
}

/* Finish : END */
/*======================================================*/
/* Cart : Start */
&.cart,
&.finish {
  .assistance__content {
    padding: 20px 0px;
  }
  #order__update__cart {
    display: none;
  }
  @media (min-width: 1024px) {
    #subheader,
    #main {
      margin-bottom: 2%;
    }
  }
}

/* Cart : End */
/*======================================================*/
/* Product : Start */
&.product {
  #order__header__languages {
    display: none;
  }
  /*========================*/
  /* Order Box : Start */
  .order__box {
    box-shadow: @orderbox-box-shadow;
    .order__box__title {
      display: none;
    }
    .order__box__content .order__box__aux2 {
      padding: 20px 15px;
    }
    .order__product__title {
      display: inline-block !important;
      h3 {
        margin-top: 0px;
      }
    }
    .order-product-info-content-wrapper {
      // width: 70%;
      display: inline-block;
    }
    .order__product__form {
      display: initial !important;
    }
    /* .order__product__options {
        & > li {
            display: inline-block;
            vertical-align: top;
            padding-right: 30px;
        }
    } */
    .order__product__info {
      //    margin-top: 30px;
      // table {
      //     display: inline-block;
      //     width: auto;
      //     vertical-align: middle;
      // }
      .order__product__buttons {
        margin-top: 20px;
      }
      .back-to-shopping-wrapper {
        display: inline-block;
      }
      .order__product__qty {
        & > p {
          display: inline-block;
          margin-right: 15px;
        }
        .quantity-field-wrapper {
          vertical-align: middle;
        }
      }
      .order__product__info__label {
        font-weight: normal;
        b {
          font-weight: normal;
        }
      }
      .order__platform__value {
        font-weight: bold;
      }
    }
    .back-to-shopping-new-wrapper {
      width: 100%;
    }
    .order__old__price__value {
      color: @brand-danger;
    }
    .order__new__price__value,
    .order__price__value {
      color: @brand-success;
      font-weight: bold;
    }
    .order__product__attributes {
      .select-box {
        width: auto !important;
        .selected-option {
          width: auto;
          padding-right: 30px;
        }
      }
      li {
        margin-bottom: 5px;
      }
    }
    .btn-success.btn-submit.btn-lg {
      margin-top: 20px;
    }
  }
  /* Order Box : END */
  /*========================*/
  .back-to-shopping-link-wrapper {
    text-align: center;
    margin-bottom: 10px;
  }
  .secure-checkout-content-text {
    text-align: center;
    .icon-lock {
      vertical-align: middle;
    }
  }
  #footer {
    border-top: none;
  }
  #order__content {
    .order__products {
      .order-product-info {
        .order__product__image {
          img {
            max-width: 100px;
          }
        }
      }
    }
  }
}

/* Product : END  */
/*======================================================*/
/* Upgrade : START  */
&.upgrade {
  .order__box .order__box__content .order__box__aux2 {
    padding: 20px 15px;
  }
  .order__product__attributes {
    .select-box {
      width: auto !important;
      .selected-option {
        width: auto;
        padding-right: 30px;
      }
    }
    li {
      margin-bottom: 5px;
    }
  }
  .order__product__form {
    width: 100%;
  }
  .order__product__image + .order__product__form {
    width: auto;
  }
}

/* Upgrade : END  */
/*======================================================*/
&.upsell_product,
& .ui-dialog-upsell {
  .select-box-arrow-wrapper {
    border-left: 0px;
  }
  .order__box__title {
    display: none;
  }
  .order__upsell__left,
  .order__upsell__right {
    display: block;
    width: 100% !important;
  }
  .order__upsell__middle {
    display: none;
  }
  .order__upsell__left {
    background: transparent !important;
    border-bottom: 1px solid @main-border-color;
  }
  .order__upsell__right {
    .order__product__buttons {
      width: 100%;
      .btn-success.btn-submit {
        float: right;
        // background-image: none;
        // padding: 12px 50px!important;
        background-image: url('/images/merchant/67caec8041b2d689a5035d3bf441c34c/arrow-to-right.png?21');
        background-repeat: no-repeat;
        background-position: 82% 54%;
        background-size: auto;
        padding: 12px 65px 12px 50px;
        transition: 0.2s;
        &:hover {
          background-position: 84% 54%;
        }
      }
    }
  }
  @media (min-width: 535px) {
    .order__upsell__left,
    .order__upsell__right {
      display: table-cell;
      width: auto !important;
    }

    .order__upsell__left {
      border-right: 1px solid @main-border-color;
      border-bottom: 0px;
    }
  }
  @media (max-width: @screen-xs-max) {
    .btn-lg {
      padding: 15px 35px;
    }

    .ui-dialog-titlebar {
      position: relative;
    }
  }
}

.valid,
.valid-select {
  border: 1px solid @brand-success !important;
}

.secure-checkout-seals {
  .avangate-logo {
    max-width: 115px !important;
  }
}

&.rtl {
  .order__listing__item__total__price {
    text-align: left !important;
  }
  #dis__text,
  .order__backupcd__option label {
    margin-right: 10px;
  }
  label[for="company_radio"],
  label[for="person_radio"] {
    margin-right: 0px;
    margin-left: 10px;
  }
  #nameoncard,
  #tiCNumber,
  #tiCVV,
  #order__totals .products-total-row.products-total.order__total span.prod-total-text,
  .back-to-shopping-link {
    float: right;
  }
  .header-display-currency {
    border-right: none;
    border-left: 1px solid @main-border-color;
  }
  #header .select-box-arrow-wrapper {
    border: none;
  }
  .select-box-arrow-wrapper {
    border-left: none;
    border-right: 1px solid @main-border-color;
  }
  &.checkout.page-with-review .btn-success.btn-submit {
    background-image: url('/images/merchant/67caec8041b2d689a5035d3bf441c34c/arrow-to-left.png?21');
    background-repeat: no-repeat;
    background-position: 20% 54%;
    padding: 12px 50px 12px 65px;
    transition: 0.2s;
    &:hover {
      background-position: 18% 54%;
    }
  }
  .payment-options-wrapper .payment-options.payment-options-combo {
    text-align: left;
  }
}

body.subscription_verifier .order__checkout__button__container {
  margin-bottom: 0;
}

@media screen and (max-width: @screen-xs-max) {
  #order__checkout__autofill__data {
    .order__box__content {
      .order__box__aux2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .paypal-express {
          padding-right: 0;
        }
      }
    }
  }
}

/*======================================================*/

/* Layout 8 : END */

/*======================================================*/
