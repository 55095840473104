.tooltip label {
    margin-bottom: 0;
}

body.ie-9 {
    #popup {
        height: 524px !important;
        width: 690px !important;
        position: absolute !important;
//        top: 40px !important;
        left: 50% !important;
        margin-left: -345px !important;
        iframe {
            height: 100% !important;
            padding-top: 20px;
            margin-top: -20px;
        }
    }
}

body.checkout #tooltip {
    border: none;
}