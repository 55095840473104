// AETCOE-19611
body.subscription_verifier {
    #subscription_email {
      .order__checkout__button__container {
        margin-bottom: 0!important;
      }
    }
}

@media screen and ( max-width: @screen-xs-max ) {
  body.subscription_verifier {
    #order__container {
      #frmCheckout {
        display: grid;
        place-items: center;
        text-align: center;
  
        #subscription_email {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
  
          #email {
            width: 100%;
          }
  
          #emailVerifySubmitBtn {
            margin-left: 0;
          }
        }
      }
    }
  }
}
// AETCOE-19611