/* FOOTER
   -------------------------------- */
#footer {
    background: @footer-bg;
    border-top: @footer-border;
    color: @footer-color;
    margin: @footer-margin;
}
#footer a {
    color: @footer-link-color;
//    text-decoration: underline;
}
/*  2.3.1 order__box */
#footer .order__box {
    border: 0;
    box-shadow: none;
    clear: both;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 5px;
}
#footer .order__box .order__box__content {
    background: none !important;
}

/* ==========================================================================
   HEADINGS
   ========================================================================== */
.assistance__title,
.hotline__title {
    font-size: @footer-headings-size;
    color: @footer-headings-color;
    font-weight: @footer-headings-weight;
    text-transform: @footer-headings-transform;
    letter-spacing: @footer-headings-letter-spacing;
    padding-bottom: unit(@footer-headings-padding-bottom, px);
    margin-top: 0;
}

/* ==========================================================================
   CONTENT
   ========================================================================== */
#order__statement__support {
    padding-bottom: 0;
}
/*
#order__statement__support p {
    padding-bottom: @footer-paragraph-padding-bottom;
}
.hotline-content {
    padding-bottom: @footer-hotline-content-padding-bottom;
}
*/
.hotline-content__nr {
    font-weight: bold;
}
.hotline-support {
    padding-top: @footer-hotline-support-padding-top;
}
#order__processedby {
    margin-top: 0;
    padding-top: @footer-order-processedby-padding-top;
    padding-bottom: @footer-hotline-content-padding-bottom;
    text-align: left;
}
#order__privacy {
    padding-top: 0;
    padding-bottom: @footer-hotline-content-padding-bottom;
    padding-top: @padding-large-vertical;
    text-align: left;
}
/*
.assistance-content__bottom {
    padding-top: @padding-large-vertical;
}
*/
.order-privacy-link-separator {
    padding: 0 5px;
}

// Right to left text
body.rtl {
    #order__processedby {
        text-align: right;
    }
    #order__privacy {
        text-align: right;
    }
}

// Media queries
@media screen and (max-width: 1089px) {
    .assistance-content__left {
        margin-bottom: @box-margin-bottom;
    }
}