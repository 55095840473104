/* ==========================================================================
   QUANTITY FIELD
   ========================================================================== */
.quantity-field-wrapper {
    border: 1px solid @table-border-color;
    border-radius: @quantity-field-border-radius;
    background-clip: padding-box;
    background: #FFF;
    display: inline-block;
}

.quantity-field-button {
    background: #FFF;
    border: 0px solid transparent;
    border-radius: 0;
    &:hover,
    &:focus,
    &:active {
        background: @quantity-field-button-bg-hover;
    }
}

.order__icon__delete {
    cursor: pointer;
    background-position: -26px -1127px;
    width: 16px;
    height: 16px;
    padding: 0px;
}
.quantity-field-wrapper .order__text__field.order__text__field__qty.text {
    color: #7d7d7d;
    background: #fff;
    text-align: center;
    width: 35px /*auto*/;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    border: 0;
    box-shadow: none;
    padding: 0;
}
.quantity-field {
    margin: 0 auto;
}

.quantity-field .icon {
    margin: 0;
}

/* ==========================================================================
   VARIATIONS
   ========================================================================== */
/* Plus Minus
   ------------------------- */
.quantity-plus-minus-aside .quantity-field-button {
    border-left: 1px solid @table-border-color;
}

/* Minus Input Plus
   ------------------------- */
.quantity-minus-input-plus .quantity-field-button.minus {
    border-right: 1px solid @table-border-color;
}
.quantity-minus-input-plus .quantity-field-button.plus {
    border-left: 1px solid @table-border-color;
}

/* Vertical Minus Input Plus
   ------------------------- */
.quantity-vertical-minus-input-plus .quantity-field-button {
    width: 100%;
}
.quantity-vertical-minus-input-plus .quantity-field-button.minus {
    border-bottom: 1px solid @table-border-color;
}
.quantity-vertical-minus-input-plus .quantity-field-button.plus {
    border-top: 1px solid @table-border-color;
}

/* Minus Plus
   ------------------------- */
.quantity-minus-plus-before .quantity-field-button {
    border-right: 1px solid @table-border-color;
}

/* Vertical Plus Input Minus
   ------------------------- */
.quantity-vertical-plus-minus-input .quantity-field-button {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    border-right: 1px solid @table-border-color;
    line-height: normal;
}
.quantity-vertical-plus-minus-input .quantity-field-button.plus {
    border-bottom: 1px solid @table-border-color;
}

/* Caret Caron
   ------------------------- */
.quantity-caret-caron .quantity-field-button {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}
.quantity-caret-caron .quantity-field__modifier.quantity-field__modifier--plus {
    border-left: 1px solid @table-border-color;
    border-bottom: 1px solid @table-border-color;
}

.quantity-caret-caron .quantity-field__modifier.quantity-field__modifier--minus {
    border-left: 1px solid @table-border-color;
}

/* ==========================================================================
   RIGHT TO LEFT TEXT
   ========================================================================== */
.rtl {
    /* Minus Input Plus
   ------------------------- */
    .quantity-minus-input-plus .quantity-field-button.minus {
        border-right: 0 solid transparent;
        border-left: 1px solid @table-border-color;
    }
    .quantity-minus-input-plus .quantity-field-button.plus {
        border-left: 0 solid transparent;
        border-right: 1px solid @table-border-color;
    }

    /* Plus Minus
   ------------------------- */
    .quantity-plus-minus-aside .quantity-field-button {
        border-left: 0 solid transparent;
        border-right: 1px solid @table-border-color;
    }

    /* Minus Plus
   ------------------------- */
    .quantity-minus-plus-before .quantity-field-button {
        border-right: 0 solid transparent;
        border-left: 1px solid @table-border-color;
    }

    /* Vertical Plus Input Minus
   ------------------------- */
    .quantity-vertical-plus-minus-input .quantity-field-button {
        border-left: 1px solid @table-border-color;
        border-right: 0 solid transparent;
    }

    /* Caret Caron
   ------------------------- */
    .quantity-caret-caron .quantity-field__modifier.quantity-field__modifier--plus {
        border-left: 0 solid transparent;
        border-right: 1px solid @table-border-color;
    }

    .quantity-caret-caron .quantity-field__modifier.quantity-field__modifier--minus {
        border-left: 0 solid transparent;
        border-right: 1px solid @table-border-color;
    }
}
