.secure-checkout-header {
    color: @box-header-color;
    font-weight: @secure-checkout-header-font-weight;
    text-transform: @secure-checkout-header-text-transform;
    font-size: @secure-checkout-header-font-size;
    padding-top: @padding-small-vertical;
    padding-bottom: @padding-large-vertical;
}

.secure-checkout-header .icon.icon-lock {
    display: inline-block;
    position: relative;
    top: 2px;
}

.secure-checkout-content,
.order__box .order__box__content.secure-checkout-content {
    width: 100%;
    background: @secure-checkout-content-bg;
    line-height: @secure-checkout-content-line-height;
    padding-bottom: @table-cell-padding;
    
    .order__box__aux2 {
        width: 100%;
    }
}

.secure-checkout-seals {
    float: right;
    margin-left: 30px;
    margin-bottom: @padding-large-vertical;
    display: block;
    max-width: 100%;
    
    .avangate-logo {
        max-width: 150px;
    }
    
    a:hover {
        text-decoration: none;
    }
}

.secure-checkout-content-table {
    width: 100%;
    
    .secure-checkout-seals {
        margin: 0;
    }
    
    .secure-checkout-content-text {
        width: 55%;
        font-size: @secure-checkout-content-font-size;
    }
}

// Right to left text
body.rtl {
    .secure-checkout-seals {
        float: left;
        margin-right: 30px;
    }
    
    .secure-checkout-content-table .secure-checkout-seals {
        margin: 0;
    }
}

// Media queries
@media screen and (max-width: 991px) {
    .secure-checkout-content-table .secure-checkout-content-text,
    .secure-checkout-content-table .secure-checkout-seals {
        display: block;
        width: 100%;
    }
    .secure-checkout-content-table .secure-checkout-seals {
        float: none;
        margin: @padding-large-vertical 0 0 0;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .secure-checkout-seals,
    body.rtl .secure-checkout-seals {
        float: none;
        margin-left: 0px;
        margin-right: 0px;
    }
}