.order__gift__option .icon {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 3px;
}

.rtl .order__gift__option .icon {
    margin-left: 5px;
    margin-right: 5px;
}

.order__gift__option label {
    margin-bottom: 0;
}