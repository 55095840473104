label {
  display: inline;
}

.custom-checkbox, .custom-radio:not(.ninja) {
  display: none !important;
}

.custom-checkbox + label, .custom-radio:not(.ninja)  + label {
  position: relative;
  margin-left: @checkbox-radio-margin-left !important;
  cursor: pointer;
  .order__checkout__form__label__error & {
    color: @brand-danger;
  }
  &:after {
    background-color: @checkbox-background;
    border: 1px solid @checkbox-border;
    padding: @checkbox-padding;
    border-radius: @checkbox-border-radius;
    top: @checkbox-top;
    left: -24px;
    content: '';
    position: absolute;
    .order__checkout__form__label__error & {
      border-color: @brand-danger;
    }
  }
}

.custom-checkbox, .custom-radio:not(.ninja)  {
  &:checked + label {

    &:before {
      content: '';
      border-bottom: 3px solid @checkbox-checked-symbol-color;
      border-right: 3px solid @checkbox-checked-symbol-color;
      height: 11px;
      .rotation(46deg);
      position: absolute;
      left: @checkbox-checked-left;
      width: @checkbox-checked-symbol-width;
      height: @checkbox-checked-symbol-height;
      z-index: 1;
      top: @checkbox-checked-top;
    }

    &:after {
      background-color: @checkbox-checked-background;
      border: 1px solid @checkbox-checked-border;
      content: '';
    }
  }
}

.custom-radio:not(.ninja)  + label {
  &:before {
    border-radius: 50%;
  }
  &:after {
    border-radius: 50%;
  }
}

.custom-radio:not(.ninja) {
  &:checked + label {
    &:after {
      border-radius: 50% !important;
      border-color: @radio-checked-border-color;
      line-height: 1;
    }
    &:before {
      background: @radio-checked-background;
      width: @radio-checked-width;
      height: @radio-checked-height;
      top: @radio-checked-top;
      left: @radio-checked-left;
      border: none;
      line-height: 1;
    }
  }
}

// remove icon images from order option
.order__dis__option, .order__backupcd__option {
  tbody tr td:nth-child(2) {
    display:none;
  }
}
