#order__page__upsell_product  {
	font-family: @font-family-base;
	background: @body-bg;
	font-size: @font-size-base;
	line-height: @line-height-computed;
	color: @text-color;
	line-height: normal;
	min-height: 100%;
}

.order__upsell__new__title,
.order__upsell__old__title,
.order__upsell__new__label {
	color: @brand-primary;
}

.ui-dialog.ui-dialog-upsell {
    line-height: @line-height-base;
    padding: 0;
    
    .ui-dialog-titlebar {
        position: absolute;
        width: 100%;
    }
    
    .ui-dialog-content {
        padding: 0;
    }
    
    .order__product__buttons {
        width: 100%;
        padding-top: 10px;
        border-top: 1px solid #DEDEDE;
    }
    
    .order__upsell__old__label,
    .order__upsell__old__title,
    .order__upsell__left,
    .order__upsell__new__label,
    .order__upsell__new__title,
    .order__upsell__right,
    #order__upsell__custom__description {
        line-height: @line-height-base;
    }
    
    .btn-submit {
        float: right;
        margin-top: 10px;
        margin-left: 10px;
    }
    
    .order__upsell__cancel__link {
        float: right;
        padding-top: @btn-padding-large-vertical;
        padding-bottom: @btn-padding-large-vertical;
        margin-top: 10px;
        a {
            color: @link-color;
            font-size: @font-size-base;
        }
    }
}

.order__upsell__left {
    background: #f4f4f4 !important;
    width: 200px !important;
    border-bottom-right-radius: 0 !important;
}

.order__upsell__left__wrap,
.order__upsell__right__wrap {
    padding: 40px 20px !important;
}

.order__upsell__old__label,
.order__upsell__new__label {
    padding-bottom: 30px;
}

.order__upsell__old__title,
.order__upsell__new__title {
    font-size: @font-size-base !important;
    margin-bottom: 20px !important;
}

.order__upsell__old__label,
.order__upsell__new__label {
    .order__box .order__box__title .order__box__aux2 !important;
    color: @text-color !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#order__upsell__description {
    padding-top: 10px;
    padding-bottom: 10px;
}

#order__upsell__custom__description {
    border: 0px solid transparent !important;
    padding-left: 0 !important;
}

.order__upsell__new__price__total__val {
    font-weight: bold;
}