/* ==========================================================================
   PRESET 5
   ========================================================================== */

#steps-wrap.preset-5 {
    #steps {
        .step {
            text-align: right;
            position: relative;
        }
        .step-box-out {
            display: block;
            position: absolute;
            top: 20px;
            right: 0;
        }
        .step-box {
            width: 2px;
            height: 10px;
            background: @cart-steps-inactive-color;
        }
        .step-nr-wrapper {
            display: none;
        }
        .step-nr-text {
            font-weight: normal;
        }
        .step.checked .step-icon {
            display: inline-block;
            padding-top: 4px;
            padding-bottom: 4px;
        }
        .step.checked .step-nr-text {
            display: none;
        }
        .step-name {
            background: transparent;
            padding-right: 5px;
            padding-top: 30px;
            text-transform: uppercase;
            max-width: 95%;
            @media only screen and (max-width : 991px) {
                max-width: 95%;
            }
        }
    }
}

.rtl #steps-wrap.preset-5 #steps {
    .step {
        text-align: left;
    }
    .step-box-out {
        right: auto;
        left: 0;
    }
    .step-name {
        padding-right: 0;
        padding-left: 5px;
    }
}

#steps-progress-bar-wrap.preset-5 {
    top: 10px;
    border: 1px solid @cart-steps-inactive-color;
    border-radius: 4px;
    background-clip: padding-box;
    padding: 5px;
    #steps-progress-bar-content {
        .step-bar-left-content,
        .step-bar-right-content {
            border-top-width: 1px;
            border-bottom-width: 1px;
        }
        .step-bar.first.checked .step-bar-left-content,
        .step-bar.first.current .step-bar-left-content {
            border-top-color: @cart-steps-active-color;
            border-bottom-color: @cart-steps-active-color;
        }
        .step-bar.last .step-bar-right-content {
            border-top-color: @cart-steps-inactive-color;
            border-bottom-color: @cart-steps-inactive-color;
        }
        .step-bar.current .step-bar-right-content {
            border-top-color: @cart-steps-active-color;
            border-bottom-color: @cart-steps-active-color;
        }
        .step-bar.last.checked .step-bar-left-content,
        .step-bar.last.current .step-bar-left-content {
            border-top-color: @cart-steps-active-color;
            border-bottom-color: @cart-steps-active-color;
        }
    }
}

@media screen and (max-width: 479px) {
    #steps-wrap.preset-5 #steps .step {
        display: table-cell;
        width: 10%;
        &.current {
            width: 70%;
        }
    }
    
}