.gradient(@color: #F5F5F5, @start: #EEE, @stop: #FFF) {
    background: @color;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, @start), color-stop(1, @stop));
    background: -ms-linear-gradient(bottom, @start, @stop);
    background: -moz-linear-gradient(center bottom, @start 0%, @stop 100%);
    background: -o-linear-gradient(@stop, @start);
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", @stop, @start));
}

.bw-gradient(@color: #F5F5F5, @start: 0, @stop: 255) {
    background: @color;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgb(@start, @start, @start)), color-stop(1, rgb(@stop, @stop, @stop)));
    background: -ms-linear-gradient(bottom, rgb(@start, @start, @start) 0%, rgb(@stop, @stop, @stop) 100%);
    background: -moz-linear-gradient(center bottom, rgb(@start, @start, @start) 0%, rgb(@stop, @stop, @stop) 100%);
    background: -o-linear-gradient(rgb(@stop, @stop, @stop), rgb(@start, @start, @start));
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)", rgb(@stop, @stop, @stop), rgb(@start, @start, @start)));
}

.bordered(@top-color: #EEE, @right-color: #EEE, @bottom-color: #EEE, @left-color: #EEE) {
    border-top: solid 1px @top-color;
    border-left: solid 1px @left-color;
    border-right: solid 1px @right-color;
    border-bottom: solid 1px @bottom-color;
}

.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
    -webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
    box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

.rounded(@radius: 2px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.border-radius(@radius: 0) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
    .background-clip(padding-box);
}

.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    -webkit-border-top-right-radius: @topright;
    -webkit-border-bottom-right-radius: @bottomright;
    -webkit-border-bottom-left-radius: @bottomleft;
    -webkit-border-top-left-radius: @topleft;
    -moz-border-radius-topright: @topright;
    -moz-border-radius-bottomright: @bottomright;
    -moz-border-radius-bottomleft: @bottomleft;
    -moz-border-radius-topleft: @topleft;
    border-top-right-radius: @topright;
    border-bottom-right-radius: @bottomright;
    border-bottom-left-radius: @bottomleft;
    border-top-left-radius: @topleft;
    .background-clip(padding-box);
}

.opacity(@opacity: 0.5) {
    -moz-opacity: @opacity;
    -khtml-opacity: @opacity;
    -webkit-opacity: @opacity;
    opacity: @opacity;
    @opperc: @opacity * 100;
    -ms-filter:~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
    filter:~"alpha(opacity=@{opperc})";
}

.transition-duration(@duration: 0.2s) {
    -moz-transition-duration: @duration;
    -webkit-transition-duration: @duration;
    -o-transition-duration: @duration;
    transition-duration: @duration;
}

.transform(...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -o-transform: @arguments;
    -ms-transform: @arguments;
    transform: @arguments;
}

.rotation(@deg: 5deg) {
    .transform(rotate(@deg));
}

.scale(@ratio: 1.5) {
    .transform(scale(@ratio));
}

.transition(@duration: 0.2s, @ease: ease-out) {
    -webkit-transition: all @duration @ease;
    -moz-transition: all @duration @ease;
    -o-transition: all @duration @ease;
    transition: all @duration @ease;
}

.inner-shadow(@horizontal: 0, @vertical: 1px, @blur: 2px, @alpha: 0.4) {
    -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
    box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}

.box-shadow(@arguments) {
    -webkit-box-shadow: @arguments;
    -moz-box-shadow: @arguments;
    box-shadow: @arguments;
}

.box-sizing(@sizing: border-box) {
    -ms-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    -webkit-box-sizing: @sizing;
    box-sizing: @sizing;
}

.user-select(@argument: none) {
    -webkit-user-select: @argument;
    -moz-user-select: @argument;
    -ms-user-select: @argument;
    user-select: @argument;
}

.columns(@colwidth: 250px, @colcount: 0, @colgap: 50px, @columnRuleColor: #EEE, @columnRuleStyle: solid, @columnRuleWidth: 1px) {
    -moz-column-width: @colwidth;
    -moz-column-count: @colcount;
    -moz-column-gap: @colgap;
    -moz-column-rule-color: @columnRuleColor;
    -moz-column-rule-style: @columnRuleStyle;
    -moz-column-rule-width: @columnRuleWidth;
    -webkit-column-width: @colwidth;
    -webkit-column-count: @colcount;
    -webkit-column-gap: @colgap;
    -webkit-column-rule-color: @columnRuleColor;
    -webkit-column-rule-style: @columnRuleStyle;
    -webkit-column-rule-width: @columnRuleWidth;
    column-width: @colwidth;
    column-count: @colcount;
    column-gap: @colgap;
    column-rule-color: @columnRuleColor;
    column-rule-style: @columnRuleStyle;
    column-rule-width: @columnRuleWidth;
}

.translate(@x: 0, @y: 0) {
    .transform(translate(@x, @y));
}

.background-clip(@argument: padding-box) {
    -moz-background-clip: @argument;
    -webkit-background-clip: @argument;
    background-clip: @argument;
}

.tab-focus() {
    // Default
    outline: thin dotted;
    // WebKit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

// Form control focus statece
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-border-focus` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
.form-control-focus(@color: @input-border-focus) {
    @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);

    &:focus {
        border-color: @color;
        outline: 0;
        background-color: @input-bg-focus;
        .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}");
    }
}

.placeholder(@color: @input-color-placeholder) {
    :-moz-placeholder {
        color: @color;
    }

    ::-webkit-input-placeholder {
        color: @color;
    }
}

// Example usage .submit-button { .background-image('lock.png'); }
// @location argument it's optional.Use it only you want to load an image form another acount.
.background-image(@image, @location: @vendor-md5) {
    background-image: url('@{resources-domain}/images/merchant/@{location}/@{image}');
}

.select-box-arrow(@rotate: 45deg) {
    border: none !important; //reset border - intended
    border-right: 2px solid @select-box-arrow-color !important;
    border-bottom: 2px solid @select-box-arrow-color !important;
    height: 10px;
    width: 10px;
    transform: rotate(@rotate);
    margin-top: -7px;
}

.page-title() {
    font-size: @font-size-h3;
    line-height: @font-size-h3 * @line-height-base;
    color: @gray-dark;
}

.main-cta() {
    background: none; //reset background - intended
    background-color: @brand-success;
    width: 100%;
    padding: 14px 20px;
    border-radius: @main-border-radius;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 22px;
    border: none;
    text-shadow: none;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(17, 202, 121, 0.25);
}

.secondary-cta() {
    .main-cta();
    padding: 13px 20px;
    border: 1px solid @brand-primary;
    background-color: #fff;
    color: @brand-primary;

    @media screen and (max-width: @screen-sm-max) {
        padding: 11px 35px;
        font-size: 14px;
        line-height: 19px;
    }
}

.box-header() {
    border-bottom: none !important;
    margin: 0 !important;
    padding: @padding-large-horizontal @padding-large-vertical !important;
    text-transform: @box-header-transform;
    font-size: @box-header-size;
    line-height: @box-header-line-height;
    font-weight: @box-header-font-weight;
}

.dialog-styles() {

    &.ui-dialog {
        border: none !important;
        border-radius: @box-border-radius;
        padding: 20px !important;
        font-family: @font-family-base !important;

        .ui-dialog-titlebar {
            padding: 0 !important;
            margin: 0 !important;

            .ui-dialog-title {
                text-transform: uppercase;
                color: @gray-dark;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }

            .ui-button {
                background: #fff !important;
            }
        }

        .ui-dialog-content {
            padding: 0 !important;
            margin: 0 !important;
            padding-top: 25px !important;
            font-size: 14px;
            line-height: 19px;
            color: @gray-dark;
            display: flex;
            flex-direction: column;
            gap: @padding-large-horizontal;

            @media screen and (max-width: 959px) {
                top: 0 !important;
            }
        }
    }
}

.upsell-product-title() {
    padding-top: @upsell-product-padding-top;
    line-height: 25px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: @upsell-product-color !important;
    text-transform: capitalize !important;
    margin-bottom: 0 !important;
}

.font-size(@size) when (@size ='small') {
    font-size: 12px !important;
    line-height: 19px !important;
}

.font-size(@size) when (@size ='medium') {
    font-size: 14px !important;
    line-height: 19px !important;
}

.font-size(@size) when (@size ='large') {
    font-size: 16px !important;
    line-height: 22px !important;
}

.font-size(@size) when (@size ='xl') {
    font-size: 24px !important;
    line-height: 33px !important;
}