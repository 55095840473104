.backup-cd-original-input {
    display: none;
}

.order__backupcd__option label .icon {
    font-size: 150%;
    color: @brand-primary;
    vertical-align: middle;
    padding-right: 5px;
}

.order__backupcd__option label button .icon {
    padding-right: 0;
}

.backup-cd-new-input {
    margin-right: 5px;
    
    .icon {
        position: relative;
        top: 2px;
        
        &.icon-plus {
            line-height: 100%;
            font-weight: normal;
            top: 0;
        }
    }
}

.rtl .backup-cd-new-input {
    margin-left: 5px;
    margin-right: 0;
}

.order__backupcd__option label {
    margin-bottom: 0;
}