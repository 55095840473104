/* ==========================================================================
   PAYMENT OPTIONS COMBO
   ========================================================================== */
.payment-options-combo {
    margin-bottom: @table-cell-padding;
    
    .payment-options-select,
    .payment-option {
        display: inline-block;
        *display: inline;
        zoom: 1;
        position: relative;
    }
    
    .payment-options-select .payment-option {
        padding: 0;
        label {
            display: block;
            padding: 5px 10px;
        }
    }
    
    .payment-options-select-list .payment-option {
        display: block;
    }

    .payment-option.selected {
        //background: #f0f0f0;
    }
}

.ie-7 .payment-option, .ie-8 .payment-option {
//    display: inline;
}

/* ==================== EXPRESS CHECKOUT ====================== */
.payment-options-wrapper-express .payment-options-combo .payment-option label {
    display: inline !important;
    padding: 0 10px !important;
}

.payment-options-wrapper-express .payment-options-combo .payment-options-select-list .payment-option label {
    display: block !important;
}

.payment-options-wrapper-express .payment-options-combo .payment-option {
    margin: 5px 0 !important;
    cursor: auto;
}

.payment-options-wrapper-express .payment-options-combo .payment-options-select {
    margin: 5px 0 !important;
}

body.page-express-payments-checkout .pay_option_dduk-fields-express-wrapper {
    margin-top: @table-cell-padding;
}

/* ==========================================================================
   DEFAULT
   ========================================================================== */
.payment-options-combo.default {
    .payment-option {
        padding: 0 0 0 10px;
        
        label {
            padding: 5px 10px 5px 0;
            display: inline-block;
        }
    }
    
    .payment-options-select .payment-option {
        padding: 0;
        
        &:hover {
            background: @main-border-color;
        }
        
        label {
            padding: 5px 10px;
            display: block;
        }
    }
}

/* ==========================================================================
   LAYOUT 1
   ========================================================================== */
.payment-options-combo.layout-1 {
    .payment-options-select,
    .payment-option {
        display: block;
    }
    
    .payment-options-select-selected-option,
    .payment-options-select-list {
        width: 100%;
    }
    
    .payment-option {
        padding: 0;
        
        label {
            padding: 5px 10px;
            display: block;
        }
    }
}

/* ==========================================================================
   LAYOUT 2
   ========================================================================== */
.payment-options-combo.layout-2 {
    border-bottom: 1px solid @main-border-color;
    
    .payment-option {
        padding: 0;
        
        label {
            padding: 10px;
            display: block;
        }
    }
    
    .payment-option:hover {
        background: @main-border-color;
    }
    
    & > .payment-option {
        border: 1px solid transparent;
        border-top-left-radius: @border-radius-large;
        border-top-right-radius: @border-radius-large;
        background-clip: padding-box;
        margin-bottom: -1px;
    }
    
    & > .payment-option.selected {
        background: @main-bg;
        border: 1px solid @main-border-color;
        border-bottom: 1px solid @main-bg;
    }
    
    .payment-options-select-list {
        width: 100%;
    }
}

/* ==========================================================================
   LAYOUT 3
   ========================================================================== */
.payment-options-combo.layout-3 {
    
    .payment-options-select {
        width: 100%;
        margin-top: floor( @table-cell-padding / 2 );
        
        .payment-options-select-selected-option {
            width: 100%;
        }
    }
    
    .payment-option {
        padding: 0 5px 0 0;
    }
    
    .payment-option.selected {
        background: transparent;
    }
    
//    .select-box-arrow-wrapper {
//        border-left: 1px solid @input-border;
//    }
    
    .payment-options-select-list {
        width: 100%;
    }
    
    .payment-option-title {
        padding-left: 0;
    }
}

/* ==========================================================================
   LAYOUT 4
   ========================================================================== */
.payment-options-combo.layout-4 {
    border-bottom: 1px solid @main-border-color;
    background: @main-border-color;
    
    .sprite-48.pay_opt_4 {
        margin-top: 0;
    }
    
    .payment-option {
        padding: 0;
        
        label {
            padding: 5px 10px 10px 10px;
            display: block;
        }
    }
    
    & > .payment-option label {
        padding: 30px 10px 10px 10px;
        
        display: block;
    }
    
    .payment-option:hover {
        background: @main-border-color;
    }
    
    & > .payment-option {
        border: 1px solid transparent;
        border-top-left-radius: @box-border-radius;
        border-top-right-radius: @box-border-radius;
        background-clip: padding-box;
        margin-bottom: -1px;
    }
    
    & > .payment-option.selected {
        background: @box-content-bg;
        border: 1px solid @main-border-color;
        border-bottom: 1px solid @box-content-bg;
    }
    
    .payment-options-select-list {
        width: 100%;
    }
    
    .payment-option-radio {
        display: block;
        margin: 10px auto -23px auto;
    }
    
//    .select-box-arrow-wrapper {
//        border-left: 1px solid @input-border;
//    }
}

/* ==========================================================================
   LAYOUT 5
   ========================================================================== */
.payment-options-combo.layout-5 {
    .payment-option,
    .payment-options-select {
        display: block;
    }
    
    & > .payment-options-select {
        padding: 21px 25px 21px 0;
        float: right;
        background: url("@{resources-domain}/images/merchant/184260348236f9554fe9375772ff966e/bck-paym-unsel.png?20150505181416") no-repeat scroll right center;
    }
    
    & > .payment-options-select.selected {
        background: url("@{resources-domain}/images/merchant/184260348236f9554fe9375772ff966e/bck-paym-sel.png?20150506112931") no-repeat scroll right center;
        .payment-options-select-selected-option-value, .payment-option.selected {
            color: @brand-primary;
        }
    }
    
    & > .payment-option {
        background: url("@{resources-domain}/images/merchant/184260348236f9554fe9375772ff966e/bck-paym-unsel.png?20150505181416") no-repeat scroll right center;
        padding: 0;
        label {
            display: block;
            padding: 21px 25px 21px 0;
            text-align: right;
        }
    }
    
    & > .payment-option.selected {
        background: url("@{resources-domain}/images/merchant/184260348236f9554fe9375772ff966e/bck-paym-sel.png?20150506112931") no-repeat scroll right center;
        color: @brand-primary;
    }
    
    & > .payment-option .payment-option-title {
        display: block;
        margin-bottom: 10px;
    }
}
.payment-options-combo.layout-5.small-icons > .payment-option label {
    padding: 26px 25px 26px 0;
}

/* ==========================================================================
   LAYOUT 6
   ========================================================================== */
.payment-options-combo.layout-6 {
    .payment-option {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }
    
    &.payment-options-sprite-32 .payment-options-select-selected-option {
        height: 40px;
        line-height: 40px;
        .select-box-arrow-wrapper {
            height: 40px;
        }
    }
    
    .payment-options-select {
        width: 100%;
        margin-top: floor( @table-cell-padding / 2 );
        
        .payment-options-select-selected-option {
            width: 100%;
        }
    }
    
    .payment-option.selected {
        background: transparent;
    }
    
    .payment-options-select-list {
        width: calc(~"100% - "(145px + 2 * @padding-small-horizontal));
    }
    
    .payment-option-title {
        padding-left: 0;
    }
}
body:not(.page-express-payments-checkout) {
    #order__checkout__billing__payoptions__table .order__checkout__billing__content {
        margin: @padding-large-vertical 0;
    }
    
    .payment-options-select #order__checkout__billing__payoptions__table .order__checkout__billing__content {
        margin-bottom: 0;
    }
    
    .payment-options-combo.layout-6 {
        margin: -(2 * @padding-large-vertical) -(@padding-small-horizontal);
        
        .payment-option {
            padding: @padding-large-vertical @padding-small-horizontal;
            &:first-of-type {
                padding-top: (2 * @padding-large-vertical);
            }
        }
        
        .payment-options-select .payment-option {
            padding: 5px 0;
        }
        
        .payment-option.selected {
            background: #f5ebd2;
            border-top: 1px solid @main-border-color;
            border-bottom: 1px solid @main-border-color;
        }
        
        .payment-option:first-child {
            border-top: 0px solid transparent;
        }
        
        .payment-options-select.selected {
            background: #f5ebd2;
            border-top: 1px solid @main-border-color;
        }
        
        .payment-options-select {
            margin-top: 0;
            padding-top: @padding-large-vertical;
            padding-right: (145px + @padding-small-horizontal);
            padding-bottom: (2 * @padding-large-vertical);
            padding-left: @padding-small-horizontal;
                
            .billing-currency-wrapper {
                width: 135px;
                position: absolute;
                right: @padding-small-horizontal;
                .select-box {
                    width: 135px !important;
                }
            }
            
            #order__checkout__form__billing__currency {
                display: table-row !important;
            }
        }
    }
}