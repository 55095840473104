.field-helper {
    position: relative;
    display: inline-block;
    bottom: -2px;

    .icon {
        color: @field-helper-popup-tooltip-color;
        padding-left: 5px;
        padding-right: 5px;
        line-height: @field-helper-icon-line-height;
    }

    .field-helper-popup {
        text-align: left;
        position: absolute;
        top: 0;
        left: 100%;
        border: @field-helper-popup-border;
        border-radius: @box-border-radius;
        z-index: 90;
        background: @field-helper-popup-bg;
        padding: @field-helper-popup-padding;
        box-shadow: @field-helper-popup-box-shadow;
        visibility: hidden;
        opacity: 0;
        color: #555;
    }

    &:hover .field-helper-popup {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.3s;
    }

    &:hover .icon {
        color: @field-helper-popup-tooltip-hover-color;
        cursor: help;
    }
}

.field-wrapper.icon-top .field-helper-icon {
    vertical-align: top;
}

.field-helper-icon {
    color: @field-helper-popup-tooltip-color;

    &:hover {
        color: @field-helper-popup-tooltip-hover-color;
        cursor: help;
        text-decoration: none;
    }
}

.field-helper.mirrored .field-helper-popup {
    left: auto;
    right: 100%;
}

.tooltip_text_wrap {
    display: none !important;
}

#order__autorenewal__container .help_icon_tooltip {
    display: none;
}

#order__autorenewal__container .field-helper-popup {
    width: 220px !important;
}


#order__autorenewal__container label,
#order__autorenewal__text {
    display: inline;
}

.label .field-helper-popup {
    font-weight: normal;
    white-space: normal;
    line-height: @line-height-computed;
}

.field-wrapper {
    padding-right: 24px;
}

// Right to left text
body.rtl {
    .field-wrapper {
        padding-left: 24px;
        padding-right: 0;
    }

    .field-helper .field-helper-popup {
        text-align: right;
        left: auto;
        right: 100%;
    }

    .field-helper.mirrored .field-helper-popup {
        left: 100%;
        right: auto;
    }
}

/* ==========================================================================
   MEDIA QUERIES
   ========================================================================== */
@media screen and (max-width: 599px) {

    body,
    body.rtl {
        .field-wrapper {
            margin-right: 0;
            padding-right: 0;
        }

        .field-helper {
            // display: none;
        }
    }
}