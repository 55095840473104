.order__text__field__mandatory.order__text__field__error,
.order__text__field.order__text__field__error {
    background-color: lighten(@brand-danger, 40%) !important;
    background-image: url("@{resources-domain}/images/merchant/aff1621254f7c1be92f64550478c56e6/error-sign.jpg?20140704103347");
    background-position: 98% center;
    background-repeat: no-repeat;
    border: 1px solid @brand-danger !important;;
    color: @brand-danger !important;
}
.valid.order__text__field__mandatory.order__text__field__error,
.valid.order__text__field.order__text__field__error {
    background-image: url("@{resources-domain}/images/merchant/aff1621254f7c1be92f64550478c56e6/error-sign.jpg?20140704103347") !important;
}

.order__select__field__mandatory
.order__select__field__error {
    background-color: lighten(@brand-danger, 80%) !important;;
    border: 1px solid @brand-danger !important;;
}
