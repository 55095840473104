body.page-partners.login_body div#header {
    position: absolute;
    top: 0;
}

body.page-partners {
    #signup_form {
        clear: both;
        .signup_form_text_input,
        .signup_form_select,
        .select-box {
            width: 250px !important;
        }
    }
    
    #frmLogin {
        .login_label {
            float: left;
            line-height: @input-height-base;
            padding-right: 5px;
        }
        
        input[type=text],
        input[type=password] {
            float: right;
        }
    }
    
    #subheader,
    #footer {
        display: none;
    }
    
    .signup_container,
    .signup_form_table {
        width: 100%;
    }
    
    #order__container {
        margin-top: @box-margin-bottom;
        margin-bottom: @box-margin-bottom;
    }
    
    #lang_select-select-box {
        width: 155px !important;
    }
    
    .signup_form_header,
    .signup_form_contact_information {
        padding: 0;
    }
    
    .signup_form_header h1,
    .signup_form_contact_information h1 {
        background-color: @box-header-bg;
        border: @box-border;
        border-radius: @box-border-radius;
        clear: both;
        display: block;
        height: auto !important;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 10px !important;
        width: 100%;
    }
    
    .signup_form_table .signup_form_text_input,
    .signup_form_table .signup_form_select {
        border-color: @input-border;
    }
    
    .signup_container {
        font-size: 100%;
    }
    
    .signup_form_table .signup_form_description, .signup_form_table .signup_form_success_body {
        line-height: inherit;
    }
    
    .signup_form_table td {
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: @table-cell-padding;
        
        &.signup_form_header,
        &.signup_form_contact_information {
            padding-bottom: 0;
        }
        
        &.signup_form_footer {
            padding-top: @table-cell-padding;
        }
    }
    
    #state-text {
         display: inline-block;
        height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
        padding: @padding-base-vertical @padding-base-horizontal;
//        font-size: @font-size-base;
        line-height: @line-height-base;
        color: @input-color;
        background-color: @input-bg;
        background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
        border: 1px solid @input-border;
        border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
//        .box-shadow(@input-box-shadow);
//        .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
//        // Customize the `:focus` state to imitate native WebKit styles.
//        .form-control-focus();
        // Disabled and read-only inputs
        //
        // HTML5 says that controls under a fieldset > legend:first-child won't be
        // disabled if the fieldset is disabled. Due to implementation difficulty, we
        // don't honor that edge case; we style them as disabled anyway.
        &[disabled], &[readonly], fieldset[disabled] & {
            background-color: @input-bg-disabled;
            opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
        }
        &[disabled],
        fieldset[disabled] & {
            cursor: @cursor-disabled;
        }
    }
}