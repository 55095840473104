#order__cart__contents .order__listing__item__image,
#order__cart__contents .order__listing__item__delivery,
#order__cart__contents .order__listing__item__qty,
#order__cart__contents .order__listing__item__unit__price,
#order__cart__contents .order__listing__item__total__price {
    padding-left: @padding-xs-horizontal;
    padding-right: @padding-xs-horizontal;
}

.products-total .order__display__total,
.products-total .order__billing__total {
    color: @products-total-price-color;
}

#order__cart__contents.default {
    .order__box .order__box__content .order__box__aux2 {
        padding: 0;
    }
    
    .products-total-left {
        vertical-align: top;
    }
    
    @media screen and (max-width: 599px) {
        .products-total-left,
        .products-total-right {
            width: 100% !important;
            display: block !important;
        }
    }
    
    .order__listing__item__name {
        color: @product-name-color;
        font-weight: bold;
        .order__product__options {
            color: @text-color;
            font-weight: normal;
        }
    }
    
    .order__product__discount .order__listing__item__name {
        color: @text-color;
        font-weight: normal;
    }
    
    #order__products {
        padding: @box-content-padding;
    }
    
    #order__totals {
        padding: @box-content-padding;
        border-top: 1px solid @table-border-color;
    }
}

.products-header-currency {
    float: right;
    width: auto;
    font-size: @font-size-base;
    line-height: 1;
    max-width: 50%;
    text-align: right;
    
    @media screen and (max-width: 767px) {
        float: none;
        max-width: none;
        text-align: inherit;
        margin-top: @padding-small-vertical;
    }
    
    .select-box,
    select {
        height: @products-box-header-currency-height;
        line-height: @products-box-header-currency-height;
    }
    
    .select-box-arrow-wrapper {
        height: (@products-box-header-currency-height - 1);
    }
    
    .select-box span.selected-option {
        height: @products-box-header-currency-height !important;
        line-height: @products-box-header-currency-height !important;
    }
}

#order__cart__contents {
    div.order__listing__item__image {
        display: inline-block;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    div.order__listing__item__image:empty {
        display: none;
    }
}

.field-wrapper.trial-price-popup .field-helper {
    right: 0;
    bottom: -5px;
}

// Right to left text
body.rtl {
    .products-header-currency {
        float: left;
        text-align: left;
    }
    
    .products__title__text {
        float: right;
    }
    
    .field-wrapper.trial-price-popup .field-helper {
        left: 0;
        right: auto;
    }
}

// Media queries
@media screen and (max-width: 675px) {
    #order__cart__contents.default {
        .order__listing__header__remove {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    #order__cart__contents.default {
        .order__listing__header__delivery,
        .order__listing__item__delivery {
            display: none;
        }
    }
}

@media screen and (max-width: 550px) {
    #order__cart__contents.default {
        .order__listing__header__total__price,
        .order__listing__item__total__price {
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    #order__cart__contents.default {
        .order__listing__header__unit__price,
        .order__listing__item__unit__price {
            display: none;
        }
    }
}

@media screen and (max-width: 400px) {
    #order__cart__contents.default {
        .order__listing__header__image,
        .order__listing__item__image {
            display: none;
        }
    }
}

// Cart product description
#order__cart__contents .order__listing__item__name .order__listing__item__description {
    font-weight: normal;
}
// Cart product popup description
.cart-product-description-popup {
    position: relative;
    display: inline-block;
    
    .icon {
        color: @x-sell-popup-tooltip-color;
        padding-left: 5px;
        padding-right: 5px;
    }
    &:hover .icon {
        color: @x-sell-popup-tooltip-hover-color;
        cursor: help;
    }
}

.cart-product-description-popup .order__listing__item__description {
    text-align: left;
    width: @x-sell-popup-width;
    position: absolute;
    top: 0;
    left: 100%;
    border: @x-sell-popup-border;
    border-radius: @box-border-radius;
    z-index: 90;
    background: @x-sell-popup-bg;
    padding: @x-sell-popup-padding;
    display: none;
    box-shadow: @x-sell-popup-box-shadow;
}

.rtl .cart-product-description-popup .order__listing__item__description {
    text-align: right;
}

.cart-product-description-popup:hover .order__listing__item__description {
    display: block;
}